import React from "react";
import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import BrushIcon from "@mui/icons-material/Brush";

interface SignDocumentCardProps {
  fileName: string;
  onSignDocument: () => void;
}

export const DownloadLink = styled("span")(({ theme }) => ({
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: "bold",
}));

export const SignDocumentCard = ({ fileName, onSignDocument }: SignDocumentCardProps) => {
  const theme = useTheme();

  return (
    <DownloadLink onClick={() => onSignDocument()} sx={{ pointerEvents: "initial" }}>
      <Paper
        elevation={3}
        sx={{
          width: "140px",
          maxWidth: "150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.main,

          transition: "all 0.4s",
          "&:hover": {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <BrushIcon sx={{ fontSize: "50px" }} />
        <Typography variant="body1" sx={{ my: 1, fontWeight: "bold", fontSize: "13px" }}>
          {fileName}
        </Typography>
        {"Unterschreiben"}
      </Paper>
    </DownloadLink>
  );
};

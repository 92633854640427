import React, { forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle, DialogActions, DialogContent, TextField } from "@mui/material";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { lighten } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { useGetTransactionByIdQuery } from "../../Store/api/transactions/transactionsApi";
import { useParams } from "react-router-dom";
import { useCustomers } from "../../hooks";
import { useNavigate } from "react-router-dom";
//import { LoadingIndicator } from "../LoadingIndicator";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const SecondaryDialogSupervisor = ({
  type = "warning",
  dialogTitle,
  contentText,
  textField,
  cancelMessage,
  setCancelMessage,
  secondaryDialogOpen,
  secondaryDialogClose,
  maxWidth = "xs",
  //deleteCustomer,
  actionButtonText,
  //  isMenuVisible,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const dialogRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();

  const transactionId = params.transactionId;
  const transactionByIdQuery = useGetTransactionByIdQuery({
    transactionId: transactionId,
  });
  const transaction = transactionByIdQuery.data;
  const { deleteCustomer } = useCustomers();
  //const [fileName, setFileName] = useState('');
  const clientFileName = transaction?.customer_name;
  const handleTextFieldChange = (e) => {
    setTextFieldValue(e.target.value);
  };
  const [textFieldValue, setTextFieldValue] = useState("");
  const customerId = transaction.customer_id;

  // for delete account with logout
  const handleDeleteTransaction = () => {
    deleteCustomer(customerId)
      .then(() => {
        handleGoToOverview();
      })
      .catch(() => {
        //dispatch(setCurrentUser({ loading: false }));
      });

    const handleGoToOverview = () => {
      navigate("/home");
      window.location.reload(true);
      window.location.assign("/");
    };
  };

  return (
    <div ref={dialogRef}>
      {
        <Dialog
          open={secondaryDialogOpen}
          onClose={() => secondaryDialogClose}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby={type}
          sx={{
            "& .MuiPaper-root": {
              width: maxWidth,
              borderRadius: "10px",
              "& .MuiDialogTitle-root": {
                backgroundColor: type === "warning" ? lighten(theme.palette.error.main, 0.8) : null,
              },
              "& .MuiDialogContent-root": {
                padding: "15px",
                [theme.breakpoints.up("md")]: {
                  padding: "24px",
                },
              },
              "& .MuiDialogActions-root": {
                padding: "15px",
                [theme.breakpoints.up("md")]: {
                  padding: "24px",
                },
              },
            },
          }}
        >
          <DialogTitle
            align="center"
            color="primary"
            sx={{
              padding: "15px",
              [theme.breakpoints.up("md")]: {
                padding: "24px",
              },
              fontWeight: "bold",
            }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent>{contentText}</DialogContent>
          {textField && (
            <TextField
              label={t("company/common:personal_talk.cancel.text_field.caption")}
              placeholder={t("company/common:personal_talk.cancel.text_field.placeholder")}
              value={cancelMessage}
              onChange={(e) => setCancelMessage(e.target.value)}
              inputProps={{
                maxLength: 1000,
              }}
              variant={"filled"}
              multiline
              minRows={5}
              maxRows={5}
              sx={{
                margin: "15px",
                [theme.breakpoints.up("md")]: {
                  margin: "24px",
                },
                fontWeight: "bold",
              }}
            />
          )}
          <TextField
            label={t("common:transaction_tables.secondaryDialogSupervisor.textFieldLabel")}
            value={textFieldValue}
            onChange={handleTextFieldChange}
            sx={{ margin: "15px" }}
            placeholder={t("common:transaction_tables.secondaryDialogSupervisor.placeholder")}
          ></TextField>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              size={isMobile ? "small" : "medium"}
              sx={{
                mr: { sx: 1, md: 3 },
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
              onClick={secondaryDialogClose}
            >
              {t("misc.cancel")}
            </Button>

            <Button
              variant="contained"
              color={type === "warning" ? "error" : "primary"}
              size={isMobile ? "small" : "medium"}
              sx={{
                px: 3,
                fontWeight: "bold",
                color: type === "warning" ? "white" : "secondary",
              }}
              onClick={handleDeleteTransaction}
              disabled={textFieldValue !== clientFileName}
            >
              {actionButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

import { API } from "aws-amplify";
import TConfig from "../../../../config";

// Warmup response definition
type WarmUpResponse = void | unknown;

// Error type definition
type WarmUpError = unknown;

export const useWarmup = () => {
  const warmUp = async (): Promise<WarmUpResponse> => {
    try {
      const response = await API.head(
        TConfig.webapp.APP_API_ID,
        `${TConfig.webapp.WARMUP_API_ENDPOINT}`,
        {} // Empty object as third argument
      );
      return response;
    } catch (error: WarmUpError) {
      //console.error("Warmup failed:", error);
      return undefined; // In case of error returns undefined
    }
  };

  return warmUp;
};
import { graphConfig } from "../../authConfig";
import { loginRequest } from "../../authConfig";
import axios from "axios";
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function getMails(msalInstance, searchQuery) {
  const headers = new Headers();
  const accessToken = (await msalInstance.instance.acquireTokenSilent(loginRequest)).accessToken;
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`${graphConfig.graphMeEndpoint}/messages?$search="${searchQuery}"`, options).then(
    (response) => response.json()
  );
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function sendMail(msalInstance, recipients, subject, body) {
  const headers = new Headers();
  const accessToken = (await msalInstance.instance.acquireTokenSilent(loginRequest)).accessToken;
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return axios
    .post(
      `${graphConfig.graphMeEndpoint}/sendMail`,
      {
        message: {
          subject: subject,
          body: {
            contentType: "Text",
            content: body,
          },
          toRecipients: recipients.map((recipient) => ({
            emailAddress: {
              address: recipient,
            },
          })),
        },
      },
      options
    )
    .then((response) => response.json());
}

import React, { useEffect, useState } from "react";
import { useTransactions } from "./useTransactions";

export const useEmailTemplate = (transactionId, language) => {
  const [subject, setSubject] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const { getTransactionById } = useTransactions();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEmailTemplate = async () => {
      try {
        setLoading(true);
        if (!transactionId) return;
        const data = await getTransactionById(transactionId);
        if (!data.transaction.metadata) return;
        const emailTemplate = data.transaction.metadata.find(
          (meta) => meta.scope === "email_template"
        );
        if (emailTemplate && emailTemplate.data[language]) {
          setSubject(emailTemplate.data[language].subject);
          setBodyMessage(emailTemplate.data[language].bodyMessage);
        }
      } catch (e) {
        // console.error('Failed to fetch email template', e);
      } finally {
        setLoading(false);
      }
    };
    fetchEmailTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, language]);

  return {
    subject,
    setSubject,
    bodyMessage,
    setBodyMessage,
    loading,
  };
};
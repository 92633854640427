import { Card, CardContent, Typography } from "@mui/material";
import { format } from "date-fns";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const RECEIVERS_MAX_LENGTH = 70;
export const ActivityCard = ({ type, email }: { type: string; email: any }) => {
  const receivedDate = format(new Date(email.receivedDateTime), "do MMMM yyyy");
  let receivers = email.toRecipients
    .map((recipient: any) => recipient.emailAddress.address)
    .join(", ");

  if (receivers.length > RECEIVERS_MAX_LENGTH) {
    receivers = receivers.substring(0, RECEIVERS_MAX_LENGTH) + "...";
  }
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            {email.subject}
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              paddingTop: "0px",
              marginTop: "0px",
            }}
          >
            <Typography variant="body2" color={"text.secondary"}>
              {receivedDate}
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <Typography color="text.secondary">{email.sender.emailAddress.address}</Typography>
              <KeyboardArrowRightIcon fontSize="small" style={{ color: "#00000099" }} />
              <Typography color="text.secondary">{receivers}</Typography>
            </div>
          </div>
          <Typography variant="body1">{email.bodyPreview}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

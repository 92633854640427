import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

type TUseMetadataProps = {
  resourceId: string;
  resourceType: string;
  data: {
    scope: string;
    data: {
      language: string;
    };
  }

}

type TUseMetadataRetVal = {
  getMetadata: (props: TUseMetadataProps) => Promise<any>
  patchMetadata: (props: TUseMetadataProps) => Promise<any>
}

export const useMetadata = (): TUseMetadataRetVal => {
  // to get transaction by transaction_id
  const getMetadata = ({resourceId, resourceType}: TUseMetadataProps) => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.TRANSACTION_API_ENDPOINT}/${resourceType}/${resourceId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  // to update transaction
  const patchMetadata = ({resourceId, data, resourceType}: TUseMetadataProps ) => {
    return API.patch(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.METADATA_API_ENDPOINT}/${resourceType}/${resourceId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
        body: data,
      }
    );
  };

  return {
    getMetadata,
    patchMetadata,
  };
};

import { Box, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import { InvestorOverview } from ".";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "600px",
  overflowY: "scroll",
};

export const InvestorOverviewModal = ({
  investor,
  transactionId,
}: {
  investor: any;
  transactionId: string | undefined;
}) => {
  const [isInvestorOpen, setIsInvestorOpen] = useState(false);
  const handleOpen = () => setIsInvestorOpen(true);
  const handleClose = () => setIsInvestorOpen(false);

  if (!transactionId) return null;
  return (
    <>
      <IconButton size="medium" onClick={handleOpen}>
        <AccountBoxIcon />
      </IconButton>
      <Modal
        open={isInvestorOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <InvestorOverview investor={investor} transactionId={transactionId} />
        </Box>
      </Modal>
    </>
  );
};

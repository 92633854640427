import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { SimpleLayout } from "../../../libs/Layout";

import {
  Logo,
  ErrorBoundary,
  ContainerWrapper,
  FadeIn,
} from "../../../elements/frontend/src/components";

const ErrorPage = ({ title, message }) => {
  const theme = useTheme();

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <ContainerWrapper width={"md"}>
          <FadeIn>
            <Box sx={{ p: { xs: 2, sm: 3 }, backgroundColor: theme.palette.common.white }}>
              <Stack direction="row" justifyContent="center" sx={{ mb: 7 }}>
                <Logo />
              </Stack>
              <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                {title}
              </Typography>
              <Typography variant="subtitle1" align="center" sx={{ my: 3 }}>
                {message}
              </Typography>
              <Typography variant="subtitle1" align="center" sx={{ my: 3 }}>
                Go to
                <Link href="/" color="error">
                  {" "}
                  Home Page
                </Link>{" "}
                and use menu to search your content.
              </Typography>
            </Box>
          </FadeIn>
        </ContainerWrapper>
      </ErrorBoundary>
    </SimpleLayout>
  );
};

export default ErrorPage;

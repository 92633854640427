import React from "react";
import { useAppSelector } from "../../../../elements/frontend/src/Store/hooks/useAppSelector";

import { Section } from "../../../../elements/frontend/src/components";
import {
  Stack,
  TextField,
  Autocomplete,
  Select,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Wrapper, FieldSet } from "./styled.PreviewInvestor";
import { CurrencyFormatter } from "../../../../elements/frontend/src/hooks/formatter/CurrencyFormatter/CurrencyFormatter";

import { useTranslation } from "react-i18next";
const PreviewInvestor = ({
  investment,
  handleDataChange,
  isValid,
  handleAutoCompleteSelect,
  handleConfirmCheck,
  convertToObjValue,
}) => {
  const { t } = useTranslation(["investor/common"]);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  return (
    <Wrapper className={investment ? "investor" : ""}>
      <Section headlineColor="black">
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
          {/*** INVEST ***/}
          <FormControl fullWidth size="small">
            <InputLabel id="invest"></InputLabel>
            <TextField
              required
              id="invest"
              name="invest"
              label={t("investor/common:content.investment.invest")}
              InputProps={{
                inputComponent: CurrencyFormatter,
              }}
              inputProps={{
                currency: "EUR",
                prefix: t("investor/common:content.investment.prefix_investment"),
                name: "invest",
                inputMode: "numeric",
              }}
              value={(currentUser.datastore.invest && investment.invest) || ""}
              size="small"
              fullWidth
              onChange={(e) => (investment !== false ? handleDataChange(e, "investment") : null)}
              error={!isValid("invest")}
            />

            {!isValid("invest") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
          </FormControl>

          {/*** REVENUE ***/}
          <FormControl fullWidth size="small">
            <InputLabel id="revenue" required>
              {t("investor/common:content.investment.revenue")}
            </InputLabel>
            <Select
              labelId="revenue"
              id="revenue"
              name="revenue"
              value={currentUser.datastore.revenue && (investment?.revenue || "")} // to fix the warning related MUI [Select] You have provided an out-of-range value.
              label={t("investor/common:content.investment.revenue")}
              onChange={(e) => handleDataChange(e, "investment")}
              error={!isValid("revenue")}
            >
              {currentUser.datastore.revenue &&
                currentUser.datastore.revenue.map(({ value, summary }, index) => (
                  <MenuItem value={value} key={index}>
                    {summary}
                  </MenuItem>
                ))}
            </Select>
            {!isValid("revenue") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
          {/*** EBITDA ***/}
          <FormControl fullWidth size="small">
            <InputLabel id="ebitda" required>
              {t("investor/common:content.investment.ebitda")}
            </InputLabel>
            <Select
              labelId="ebitda"
              id="ebitda"
              name="ebitda"
              value={currentUser.datastore.revenue && (investment?.ebitda || "")} // to fix the warning related MUI [Select] You have provided an out-of-range value.
              label={t("investor/common:content.investment.ebitda")}
              onChange={(e) => handleDataChange(e, "investment")}
              error={!isValid("ebitda")}
            >
              {currentUser.datastore.ebitda &&
                currentUser.datastore.ebitda.map(({ value, summary }, index) => (
                  <MenuItem value={value} key={index}>
                    {summary}
                  </MenuItem>
                ))}
            </Select>
            {!isValid("ebitda") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
          </FormControl>

          {/*** STAKE ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="stake"
              freeSolo
              options={currentUser.datastore.stake}
              getOptionLabel={(option) => option.summary}
              value={convertToObjValue(investment?.stake, "stake") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t("investor/common:content.investment.stake")}
                  error={!isValid("stake")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "stake")}
              isOptionEqualToValue={(option, value) => option.summary === value.summary}
            />
            {!isValid("stake") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
          {/*** TYPES ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="types"
              freeSolo
              options={currentUser.datastore.types}
              getOptionLabel={(option) => option.summary}
              value={convertToObjValue(investment?.types, "types") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t("investor/common:content.investment.types")}
                  error={!isValid("types")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "types")}
              isOptionEqualToValue={(option, value) => option.summary === value.summary}
            />
            {!isValid("types") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
          {/*** PERIOD ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="period"
              freeSolo
              options={currentUser.datastore.period}
              getOptionLabel={(option) => option.summary}
              value={convertToObjValue(investment?.period, "period") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t("investor/common:content.investment.period")}
                  error={!isValid("period")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "period")}
              isOptionEqualToValue={(option, value) => option.summary === value.summary}
            />
            {!isValid("period") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
          <FieldSet
            sx={{
              pb: 1,
              display: "flex",
              direction: { xs: "column", md: "row" },
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <legend>{t("investor/common:content.investment.contribution.legend")}</legend>

            <FormControlLabel
              control={
                <Checkbox
                  value="strategic_investor"
                  color="primary"
                  size="small"
                  checked={
                    investment && investment.contribution
                      ? investment?.contribution?.strategic_investor
                      : false
                  }
                />
              }
              label={
                <Typography variant="body1">
                  {t("investor/common:content.investment.contribution.strategic_investor")}
                </Typography>
              }
              onChange={handleConfirmCheck("contribution", "strategic_investor")}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="financial_investor"
                  color="primary"
                  size="small"
                  checked={
                    investment && investment.contribution
                      ? investment?.contribution?.financial_investor
                      : false
                  }
                />
              }
              label={
                <Typography variant="body1">
                  {t("investor/common:content.investment.contribution.financial_investor")}
                </Typography>
              }
              onChange={handleConfirmCheck("contribution", "financial_investor")}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="wachstums_investor"
                  color="primary"
                  size="small"
                  checked={
                    investment && investment.contribution
                      ? investment?.contribution?.wachstums_investor
                      : false
                  }
                />
              }
              label={
                <Typography variant="body1">
                  {t("investor/common:content.investment.contribution.wachstums_investor")}
                </Typography>
              }
              onChange={handleConfirmCheck("contribution", "wachstums_investor")}
            />
          </FieldSet>
        </Stack>
      </Section>
    </Wrapper>
  );
};

export default PreviewInvestor;

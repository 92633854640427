import React, { useEffect, useState } from "react";
import { Box, Card, Typography, IconButton } from "@mui/material";
import { Section } from "../../elements/frontend/src/components/Section/Section";
import InvestorInvite from "../../elements/frontend/src/components/InvestorMatching/InvestorInvite/InvestorInvite";
import InvestorTable from "../../elements/frontend/src/components/InvestorTable/InvestorTable";
import useInvestorMatching from "./useInvestorMatching";
import { InvestmentTeaserPlatform } from "../../elements/frontend/src/components/InvestorTable/InvestmentTeaserPlatform";
import CustomInvestorForm from "../../elements/frontend/src/components/InvestorMatching/CustomInvestorForm/CustomInvestorForm";
import { useParams } from "react-router";
import { useInvestmentTeaser } from "../../elements/frontend/src/hooks/useInvestmentTeaser";
import AddIcon from "@mui/icons-material/Add";
import { InvestorProps } from "../../elements/frontend/src/Store/interfaces";
import InvestorTablePopup from "../../elements/frontend/src/components/InvestorTable/InvestorTablePopup";
import InvestorAnalyticsPoupup from "../../elements/frontend/src/components/InvestorTable/InvestorAnalyticsPoupup";
import { InvestorOverviewModal } from "./InvestorOverview/InvestorOverviewModal";
import ClearIcon from "@mui/icons-material/Clear";
import ActionMenu from "../../elements/frontend/src/components/ActionMenu/ActionMenu";
import InvestmentDossierPlatform from "../../elements/frontend/src/components/InvestorTable/InvestmentDossierPlatform";
import { EmailTemplate } from "../../elements/frontend/src/components/InvestorMatching/EmailTemplate";
import { Provider, useDispatch, useSelector } from "react-redux";
import localizedStore, {
  LocalizedStoreState,
  LocalizedStoreDispatch,
} from "../../elements/frontend/src/Store/languageChange/languageStore";
import {
  loadLanguage,
  setLanguage,
} from "../../elements/frontend/src/Store/languageChange/languageSlice";

const useDisabler = (transactionId: any) => {
  const [disabled, setDisabled] = useState(true);
  const { getPuplicFile } = useInvestmentTeaser();

  useEffect(() => {
    const getFile = async () => {
      const file = await getPuplicFile(transactionId);

      if (file.files.length !== 0) {
        setDisabled(false);
      }
    };

    getFile();
  }, [transactionId, getPuplicFile]);

  return { disabled };
};

const InvestorMatching: React.FC = () => {
  const {
    investors,
    loadingInvestors,
    invitations,
    loadingInvitations,
    isInvited,
    expandCategories,
    setExpandCategories,
    addCustomInvestor,
    inviteInvestor,
    unInviteInveestor,
  } = useInvestorMatching();

  const { transactionId } = useParams<{ transactionId: string }>();
  const { disabled } = useDisabler(transactionId);

  const dispatch = useDispatch<LocalizedStoreDispatch>();
  const language = useSelector((state: LocalizedStoreState) =>
    transactionId ? state.language[transactionId] : "de"
  );

  useEffect(() => {
    if (transactionId) {
      dispatch(loadLanguage({ transactionId }));
    }
  }, [dispatch, transactionId]);

  const handleLanguageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (transactionId) {
      dispatch(setLanguage({ transactionId, language: e.target.value as string }));
    }
  };

  const isinvited = (investor: InvestorProps) => {
    return invitations.some((inv) => inv.id === investor.id);
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <div>
        <Section>
          <Typography
            variant="h5"
            sx={{
              marginLeft: "25px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Investoren
          </Typography>
        </Section>
      </div>
      <Section>
        <Card
          style={{
            maxWidth: "95%",
            marginLeft: "25px",
            marginBottom: "100px",
          }}
        >
          <InvestorTable
            disabled={disabled}
            investors={investors?.filter((inv: any) => !isinvited(inv))}
            loading={loadingInvestors}
            expandCategories={expandCategories}
            setExpandCategories={setExpandCategories}
            renderActions={(investor: any) => {
              return (
                <div>
                  <IconButton size="medium" onClick={() => inviteInvestor(investor)}>
                    <AddIcon />
                  </IconButton>
                </div>
              );
            }}
          />
          <CustomInvestorForm addCustomInvestor={addCustomInvestor} />
        </Card>
        <Section>
          <Typography
            variant="h5"
            sx={{
              marginLeft: "25px",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Eingeladene Investoren für das Unternehmen
          </Typography>
        </Section>
        <Card
          style={{
            maxWidth: "95%",
            marginLeft: "25px",
          }}
        >
          <InvestorInvite
            invitations={invitations}
            investors={investors}
            loadingInvitations={loadingInvitations}
            isInvited={isInvited}
            expandCategories={expandCategories}
            setExpandCategories={setExpandCategories}
            disabled={disabled}
            unInviteInveestor={unInviteInveestor}
            renderActions={(investor: InvestorProps, disabled: boolean) => {
              return (
                <ActionMenu
                  buttons={[
                    {
                      title: "Investment Teaser",
                      element: (
                        <IconButton size="medium">
                          <InvestorTablePopup investor={investor} disabled={disabled} />
                        </IconButton>
                      ),
                    },
                    {
                      title: "Investment Teaser Analytics",
                      element: (
                        <IconButton size="medium">
                          <InvestorAnalyticsPoupup investor={investor} disabled={disabled} />
                        </IconButton>
                      ),
                    },
                    {
                      title: "Investor Overview",
                      element: (
                        <IconButton size="medium">
                          <InvestorOverviewModal
                            investor={investor}
                            transactionId={transactionId}
                          />
                        </IconButton>
                      ),
                    },
                    {
                      title: "Uninvite",
                      element: (
                        <IconButton size="medium">
                          <ClearIcon onClick={() => unInviteInveestor(investor)} />
                        </IconButton>
                      ),
                    },
                  ]}
                />
              );
            }}
          />
          <Provider store={localizedStore}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <InvestmentTeaserPlatform
                language={language}
                onLanguageChange={handleLanguageChange}
                disabled={disabled}
              />
              <InvestmentDossierPlatform />
              <EmailTemplate transactionId={transactionId} />
            </div>
          </Provider>
        </Card>
        <div
          style={{
            width: "fit-content",
            marginLeft: "25px",
          }}
        ></div>
      </Section>
    </Box>
  );
};

export default InvestorMatching;

import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";
import { expirableLocalStorage } from "expirable-storage";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import {
  Card,
  LoadingIndicator,
  Section,
  DetailsCompanyCard,
  DetailsContactCard,
  DetailsCompanyInformationCard,
  DetailsMarketAnalysisCard,
  DocumentsTable,
  DownloadFileCard,
  PrimaryButton,
} from "../../elements/frontend/src/components";
import { useClients, useTransactions, useDocuments } from "../../elements/frontend/src/hooks";
import { getValueFromMetadata, s3Upload } from "../../elements/frontend/src/common";
import TConfig from "../../config";
import { DashbordContext, DashboardDispatch } from "../../views/Private/Home";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";
import { useGetCategoryTreesQuery } from "../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { useParams } from "react-router-dom";
import { DetailsInterestCard } from "../../elements/frontend/src/components/DetailsInterestCard/DetailsInterestCard";
import { AdditionalCompanyInformation } from "../../elements/frontend/src/components/AdditionalCompanyInformation/AdditionalCompanyInformation";
import { useMediaQuery } from "@mui/material";
import { UploadFileCard } from "./UploadFileCard";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../elements/frontend/src/Store/api/transactions/transactionsApi";
import { SignDocumentCard } from "./SignDocumentCard";
import {
  authenticateUserForDocuSign,
  useDocuSignAccessToken,
  getUserAccount,
} from "../../api/docuSign";
import { DocumentSignDialog } from "./DocumentSignDialog";
import { useForm } from "react-hook-form";

//import { ClientAgreement } from '../ClientAgreement/index'

const companyRepresentitiveEmail = "hk@beteiligungsboerse.eu";

const Details = () => {
  //const [accessToken] = useDocuSignAccessToken();
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();
  const params = useParams();

  const transactionId = params.transactionId;
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const [initial, setInitial] = useState(true);
  // const { getDocumentCompany } = useDocuments();
  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;

  const [documentToSign, setDocumentToSign] = useState(null);
  const { getClient, updateClient } = useClients();
  const { getTransactionById, updateTransaction } = useTransactions();
  const [updateTransactionMutation] = useUpdateTransactionMutation();
  const { getDocumentPlatform } = useDocuments();

  const dashboardContext = useContext(DashbordContext);

  const dashboardDispatch = useContext(DashboardDispatch);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const methods = useForm({
    mode: "all",
    defaultValues: {
      client: currentUser.attributes.customer_id,
    },
  });
  //const companyName = currentUser.customer.name;

  const { watch, /* handleSubmit, */ setValue } = methods;

  const transaction = watch("transaction");

  const products_dummy = [
    {
      product_name: "",
      revenue_type: "",
      sales_volume: 0,
    },
    {
      product_name: "",
      revenue_type: "",
      sales_volume: 0,
    },
    {
      product_name: "",
      revenue_type: "",
      sales_volume: 0,
    },
  ];

  const customers_dummy = [
    {
      customer_name: "",
      big_volume: 0,
      revenue_volume: 0,
    },
    {
      customer_name: "",
      big_volume: 0,
      revenue_volume: 0,
    },
    {
      customer_name: "",
      big_volume: 0,
      revenue_volume: 0,
    },
  ];

  const [detailsTransaction, setDetailsTransaction] = useState({});

  const [clientData, setClientData] = useState({});

  const [customerProducts, setCustomerProducts] = useState();
  const [customersData, setCustomersData] = useState();
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  // to manage to validate all fields
  const [formState /* setFormState */] = useState({
    initial: true,
    valid: false,
  });
  //   const transaction_id = detailsTransaction.transaction_id;
  const [loadingDetails, setLoadingDetails] = useState(false);

  const transactionByIdQuery = useGetTransactionByIdQuery(
    {
      transactionId: transactionId,
    },
    { skip: !transactionId }
  );

  const stateMetadata = transactionByIdQuery.data?.metadata.find(
    (metadata) => metadata.scope === "state"
  )?.data;

  const susaData = useMemo(() => {
    const initialData = {
      scope: "susa",
      data: {
        sales_prev_year: "",
        sales_last_year: "",
        ebitda_prev_year: "",
        ebitda_last_year: "",
        ebit_prev_year: "",
        ebit_last_year: "",
        number_employees_prev_year: "",
        number_employees_last_year: "",
        ownerships: [],
      },
    };

    if (Object.keys(clientData).length > 0) {
      const susaScope = clientData?.metadata?.find((item) => item.scope === "susa");
      return susaScope || initialData;
    }

    return initialData;
  }, [clientData]);

  const ContentRef = useRef();
  const CompanyRef = useRef();
  const ContactRef = useRef();
  const CompanyInformationRef = useRef();
  const MarketAnalysisRef = useRef();
  const DocumentDownloadRef = useRef();
  const DocumentsManagementRef = useRef();
  const AdditionalCompanyInformationRef = useRef();
  const DetailsInterestCardRef = useRef();

  useEffect(() => {
    if (dashboardContext.scrollTo) return;
    window.scrollTo(0, 0);
  }, [dashboardContext.scrollTo]);
  useEffect(() => {
    if (!dashboardContext.scrollTo) return;

    const refs = {
      ContentRef,
      CompanyRef,
      ContactRef,
      CompanyInformationRef,
      MarketAnalysisRef,
      DocumentDownloadRef,
      DocumentsManagementRef,
      AdditionalCompanyInformationRef,
      DetailsInterestCardRef,
    };

    const top = refs[dashboardContext.scrollTo].current?.offsetTop;
    const SCROLL_THRESHOLD = 135;
    const contentTop = ContentRef.current?.scrollTop;

    if (contentTop !== undefined && top !== undefined) {
      if (contentTop + SCROLL_THRESHOLD === top) {
        window.scrollBy({
          top: -1 * SCROLL_THRESHOLD,
          behavior: "smooth",
        });
        setTimeout(() => {
          window.scrollTo({
            top: top - SCROLL_THRESHOLD,
            behavior: "smooth",
          });
          return;
        }, 100);
      }
    }
    if (top !== undefined) {
      ContentRef.current &&
        window.scrollTo({
          top: top - SCROLL_THRESHOLD,
          behavior: "smooth",
        });
    }
  }, [dashboardContext.scrollTo]);

  useEffect(() => {
    setLoadingDetails(true);
    getTransactionById(transactionId)
      .then((response) => {
        // to assign the values of period, phase, stake and type fields initially
        let transactionObj = response.transaction;

        getClient(transactionObj.client_id).then((clientResponse) => {
          let clientObj = clientResponse.client;
          const scope_susa = clientResponse.client?.metadata?.find((item) => item.scope === "susa");

          const scope_profile = clientResponse.client?.metadata?.find(
            (item) => item.scope === "profile"
          );
          const products = scope_profile?.data?.products_data || products_dummy;
          const customers = scope_profile?.data?.customers_data || customers_dummy;

          // it will add period, phase, stake and type as investor profile data in transaction invest field.
          if (
            !transactionObj?.invest?.period &&
            !transactionObj?.invest?.phase &&
            !transactionObj?.invest?.stake &&
            !transactionObj?.invest?.type
          ) {
            transactionObj = {
              ...transactionObj,
              invest: {
                ...transactionObj.invest,
                period: "",
                phase: "",
                stake: currentUser.datastore.companyStake.map((item) => item.value),
                type: currentUser.datastore.types.map((item) => item.value),
              },
            };
          }

          if (scope_susa === undefined) {
            clientObj = {
              ...clientObj,
              metadata: [...clientObj.metadata, susaData],
            };
          }
          setClientData(clientObj);
          setDetailsTransaction(transactionObj);
          setCustomerProducts(products);
          setCustomersData(customers);
          setLoadingDetails(false);
          dashboardDispatch({
            type: "UPDATE_DATA",
            payload: {
              detailsContentLoading: false,
            },
          });
        });
      })
      .catch(() => {
        setLoadingDetails(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subCategoryIds = useMemo(() => {
    const value = getValueFromMetadata("profile", "sub_categories", clientData?.metadata);
    if (!value) {
      return [];
    }
    return value;
  }, [clientData?.metadata]);

  // to update clientData state
  const handleClientDataChange = (e, field, isMetadata = false) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientData((prevState) => {
      if (isMetadata) {
        const isScopeExist = clientData?.metadata?.find((item) => item.scope === field);

        if (!isScopeExist) {
          if (!prevState?.metadata) {
            prevState.metadata = [];
          }

          prevState.metadata.push({ scope: field, data: { [name]: value } });
        }

        const metaData = prevState.metadata.map((item) => {
          // field is the scope in metadata
          if (item.scope === field) {
            if (field === "susa") {
              // to convert string value with thousandseperator to number value
              const numberValue = value.split(".").join("");
              return {
                ...item,
                data: {
                  ...item.data,
                  [name]: numberValue,
                },
              };
            } else {
              return {
                ...item,
                data: {
                  ...item.data,
                  [name]: value,
                },
              };
            }
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      } else if (field === "name") {
        return {
          ...prevState,
          [field]: value,
        };
      } else {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: value,
          },
        };
      }
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // to update detailsTransaction state
  const handleTransactionDataChange = (e, field) => {
    const value = e.target.value;
    const name = e.target.name;

    const isFieldExist = detailsTransaction.hasOwnProperty(field);

    setDetailsTransaction((prevState) => {
      if (!isFieldExist) {
        prevState[field] = {
          [name]: value,
        };
      }

      if (field === "invest") {
        // to convert string value with thousandseperator to number value
        const numberValue = value.split(".").join("");
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: numberValue,
          },
        };
      } else {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: value,
          },
        };
      }
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // handle change event for trade_registration
  const handleTradeRegistrationChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // if there is no metadata in clientData, then create scope "profile" with categories field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { [name]: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { [name]: [] } }],
          };
        });
      }
    }

    // pattern will be HRA-12345 / HRB-12345
    if (value.length === 0) {
      value = "HR";
    }
    if (value.length === 3) {
      if (value.toLowerCase().endsWith("a") || value.toLowerCase().endsWith("b")) {
        value = value.toUpperCase() + "-";
      }
    }

    setClientData((prevState) => {
      const metaData = prevState?.metadata?.map((item) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  const handlePhoneChange = (value) => {
    setClientData((prevState) => {
      return {
        ...prevState,
        contact: {
          ...prevState.contact,
          telephone: value,
        },
      };
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // to validate phone number with libphonenumber-js
  const checkPhoneNumber = (value, initial = true) => {
    if (initial && formState.initial) return true;
    const phone = parsePhoneNumber(value);
    return phone.isValid();
  };

  const handleCategoriesSelect = (categories, type) => {
    const categoryIds = categories.map((category) => category.value);
    // if there is no metadata in clientData, then create scope "profile" with categories field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { categories: [], sub_categories: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState) => {
          return {
            ...prevState,
            metadata: [
              ...prevState.metadata,
              { scope: "profile", data: { categories: [], sub_categories: [] } },
            ],
          };
        });
      }
    }

    // handle change for first Autocomplete , which is "categories"
    if (type === "category") {
      setClientData((prevState) => {
        const metaData = prevState.metadata.map((item) => {
          // field is the scope in metadata
          if (item.scope === "profile") {
            return {
              ...item,
              data: {
                ...item.data,
                categories: categoryIds,
                sub_categories: categoryTrees
                  ? removeAllChildSubCategoriesWithoutSelectedRootCategory(
                      categoryIds,
                      subCategoryIds,
                      categoryTrees
                    )
                  : subCategoryIds,
              },
            };
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      });
    } else if (type === "subCategory") {
      setClientData((prevState) => {
        const metaData = prevState.metadata.map((item) => {
          // field is the scope in metadata
          if (item.scope === "profile") {
            return {
              ...item,
              data: {
                ...item.data,
                sub_categories: categoryIds,
              },
            };
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      });
    }
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // handle the changes on customers table
  const handleCustomersDataChange = (e, index) => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.split(".").join("");
    let newData = [];

    setCustomersData((prevState) => {
      newData = prevState.map((item, ind) => {
        if (ind === index) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });
      return newData;
    });

    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }

    // if there is no metadata in clientData, then create scope "profile" with customers_data field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { customers_data: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { customers_data: [] } }],
          };
        });
      }
    }

    setClientData((prevState) => {
      const metaData = prevState.metadata.map((item) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              customers_data: newData,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
  };

  // handle the changes on products table
  const handleProductDataChange = (e, index) => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.split(".").join("");
    let newData = [];

    setCustomerProducts((prevState) => {
      newData = prevState.map((item, ind) => {
        if (ind === index) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });
      return newData;
    });

    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }

    // if there is no metadata in clientData, then create scope "profile" with products_data field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { products_data: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { products_data: [] } }],
          };
        });
      }
    }

    setClientData((prevState) => {
      const metaData = prevState.metadata.map((item) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              products_data: newData,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
  };

  const handleInvestorProfileAutoCompleteSelect = (e, value, field) => {
    if ((value && field === "type") || field === "stake") {
      // syntax of value is [{value : "...", summary: "..."}, {value : "...", summary: "..."}]
      const newValue = value.map((item) => item.value);

      setDetailsTransaction((prevState) => {
        return {
          ...prevState,
          invest: {
            ...prevState.invest,
            [field]: newValue,
          },
        };
      });
      // to make save button enable
      if (disableSaveButton) {
        setDisableSaveButton(false);
      }
    }

    if ((value && field === "phase") || field === "period") {
      const newValue = value.value;

      setDetailsTransaction((prevState) => {
        return {
          ...prevState,
          invest: {
            ...prevState.invest,
            [field]: newValue,
          },
        };
      });
      // to make save button enable
      if (disableSaveButton) {
        setDisableSaveButton(false);
      }
    }

    if (field === "structure") {
      const newValue = value.cat;

      setDetailsTransaction((prevState) => {
        return {
          ...prevState,
          invest: {
            ...prevState.invest,
            [field]: newValue,
          },
        };
      });
    }

    setDisableSaveButton(false);
  };

  // to update the client and transaction data in DB
  const handleUpdateClientTransactionData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (clientData.contact.telephone) {
        const phone = parsePhoneNumber(clientData.contact.telephone);
        const isValidPhoneNumber = phone.isValid();
        const formattedNumber = phone.formatInternational();

        if (isValidPhoneNumber) {
          setClientData((prevState) => {
            return {
              ...prevState,
              contact: {
                ...prevState.contact,
                telephone: formattedNumber,
              },
            };
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      // update client data
      const responseClient = await updateClient(clientData.client_id, clientData);

      // update transaction data
      const responseTransaction = await updateTransaction(
        detailsTransaction.transaction_id,
        detailsTransaction
      );

      if (responseClient || responseTransaction) {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };
  const typeName = {
    avv: "Auftragsverarbeitungsvertrag",
    nda_company: "Vertraulichkeitserklärung",
    contract_company: "Auftragsvereinbarung",
    nda_transaction: "Geheimhaltungsvereinbarung",
  };
  const [fileToDownload, setFileToDownload] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const custom_company_contract = stateMetadata?.custom_company_contract ?? false;
  const handleDownloadDocument = async (type) => {
    setSelectedType(type);
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    /*  const custom_company_contract = detailsTransaction?.metadata?.find(
      (item) => item.scope === "state"
    )?.data?.custom_company_contract; */

    const params = {
      type: type,
      customer_id: detailsTransaction.customer_id,
      client_id: detailsTransaction.client_id,
      transaction_id: detailsTransaction.transaction_id,
      //custom_company_contract: custom_company_contract,
    };

    getDocumentPlatform(params)
      .then((response) => {
        setFileToDownload(response.download_url);
        // update transaction state "avv_received" and "nda_received" after downloading
        const transaction_id = detailsTransaction.transaction_id;
        let objTransactionData = {};
        if (type === "avv") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  avv_received: true,
                },
              },
            ],
          };
        } else if (type === "nda_company") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  nda_received: true,
                },
              },
            ],
          };
        } else if (type === "contract_company") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  contract_company_received: true,
                },
              },
            ],
          };
        }

        updateTransaction(transaction_id, objTransactionData).then((responseTransaction) => {
          if (responseTransaction) {
            dispatch(
              setCurrentUser({
                loading: false,
              })
            );
          }
        });
      })
      .catch(() => {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      });
  };

  const handleSusaDataChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setClientData((prevState) => {
      const metaData = prevState.metadata.map((item) => {
        if (item.scope === "susa") {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          };
        }
        return item;
      });

      return { ...prevState, metadata: [...metaData] };
    });

    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  const handleOwnershipChange = (ownerships) => {
    const eventData = { target: { name: "ownerships", value: ownerships } };
    handleSusaDataChange(eventData);
  };

  const companyName = detailsTransaction.customer_name;

  const [accessToken, accountId] = useDocuSignAccessToken();
  const [localAccessToken, setLocalAccessToken] = useState(accessToken || "");
  const [localAccountId, setLocalAccountId] = useState(accountId || "");

  // here we check token and trigger the authentication if necessary
  useEffect(() => {
    async function checkAndAuthenticate() {
      try {
        const token = expirableLocalStorage.getItem("docuSignAccessToken");

        // Only authenticate if no token exists in storage
        if (!token) {
          authenticateUserForDocuSign();
        } else {
          // Only update if token is different from local state
          if (token !== localAccessToken) {
            setLocalAccessToken(token);

            // Fetch account data only if token is valid
            const account = await getUserAccount(token);
            if (account && account.account_id && account.account_id !== localAccountId) {
              setLocalAccountId(account.account_id);
            }
          }
        }
      } catch (error) {
        // console.error("Error during authentication:", error);
      }
    }

    // Call the authentication function only when the component mounts
    checkAndAuthenticate();

    // Event listener to handle storage changes
    const handleStorageChange = (event) => {
      if (event.key === "docuSignAccessToken") {
        checkAndAuthenticate();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Only run once on mount

  // This useEffect listens to changes in `useDocuSignAccessToken` hook
  useEffect(() => {
    if (accessToken !== localAccessToken) {
      // console.log("Updating localAccessToken from useDocuSignAccessToken:", accessToken);
      setLocalAccessToken(accessToken);
    }
    if (accountId !== localAccountId) {
      // console.log("Updating localAccountId from useDocuSignAccessToken:", accountId);
      setLocalAccountId(accountId);
    }
  }, [accessToken, accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (localAccessToken) {
      setLocalAccessToken();
    }
    if (localAccountId) {
      setLocalAccountId();
    }
  }, [localAccessToken, localAccountId]);

  const handleSignDocument = async (type) => {
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    const params = {
      type: type,
      customer_id: detailsTransaction.customer_id,
      client_id: detailsTransaction.client_id,
      transaction_id: detailsTransaction.transaction_id,
      custom_company_contract: custom_company_contract,
    };

    try {
      // Fetch the document platform URL
      const response = await getDocumentPlatform(params);
      setDocumentToSign(response.download_url);

      const transaction_id = detailsTransaction.transaction_id; // This should always be a string

      let objTransactionData = {};

      // Update the transaction data depending on the document type
      if (type === "contract_company") {
        objTransactionData = {
          metadata: [
            {
              scope: "state",
              data: {
                contract_company_received: true,
              },
            },
          ],
        };
      }

      // Call updateTransaction with the correct transactionId and data
      const responseTransaction = await updateTransaction(transaction_id, objTransactionData);

      if (responseTransaction) {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      }
    } catch (error) {
      //console.error("Error in handleSignDocument:", error);
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  useEffect(() => {
    if (transactionByIdQuery.isSuccess) {
      setValue("transaction", transactionByIdQuery.data);
    }
  }, [setValue, transactionByIdQuery]);

  const docuSignEnvelopeId = stateMetadata?.contract_company_envelope_id ?? null;

  const isFinalized = stateMetadata?.is_finalized ?? false;

  const metadata = transaction?.metadata ? transaction.metadata : [];

  const secondaryScope = metadata?.find((item) => item.scope === "secondary");

  const accountEmail = transaction?.client_contact?.email;

  let secondaryEmails = secondaryScope?.data?.adresses?.map((address) => address.email) ?? [];
  const emails = [...new Set([...secondaryEmails, accountEmail, companyRepresentitiveEmail])];

  if (!stateMetadata) {
    return <div></div>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: theme.breakpoints.values.xl,
      }}
      ref={ContentRef}
    >
      <DetailsCompanyCard
        clientData={clientData}
        handleClientDataChange={handleClientDataChange}
        handleTradeRegistrationChange={handleTradeRegistrationChange}
        loading={loadingDetails}
        ref={CompanyRef}
      />
      <DetailsContactCard
        transaction={detailsTransaction}
        clientData={clientData}
        handleClientDataChange={handleClientDataChange}
        handlePhoneChange={handlePhoneChange}
        checkPhoneNumber={checkPhoneNumber}
        loading={loadingDetails}
        ref={ContactRef}
      />
      <DetailsCompanyInformationCard
        clientData={clientData}
        productsData={customerProducts}
        customersData={customersData}
        handleClientDataChange={handleClientDataChange}
        handleCategoriesSelect={handleCategoriesSelect}
        handleProductDataChange={handleProductDataChange}
        handleCustomersDataChange={handleCustomersDataChange}
        loading={loadingDetails}
        ref={CompanyInformationRef}
        initial={initial}
        setInitial={setInitial}
      />
      <DetailsInterestCard
        transaction={detailsTransaction}
        handleTransactionDataChange={handleTransactionDataChange}
        handleInvestorProfileAutoCompleteSelect={handleInvestorProfileAutoCompleteSelect}
        loading={loadingDetails}
        ref={DetailsInterestCardRef}
      />
      <DetailsMarketAnalysisCard
        transaction={detailsTransaction}
        handleTransactionDataChange={handleTransactionDataChange}
        loading={loadingDetails}
        ref={MarketAnalysisRef}
      />
      <AdditionalCompanyInformation
        susa={susaData}
        handleSusaDataChange={handleSusaDataChange}
        handleOwnershipChange={handleOwnershipChange}
        ref={AdditionalCompanyInformationRef}
      />
      <Section title={t("company/common:onboarding.documents.headline")}>
        <Card>
          <Stack direction="row" spacing={2} ref={DocumentDownloadRef}>
            <DownloadFileCard
              type={"avv"}
              fileName={t("company/common:onboarding.documents.avv")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              type={"nda_company"}
              fileName={t("company/common:onboarding.documents.nda")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              type={"contract_company"}
              fileName={t("company/common:client_agreement.documents.client_agreement")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              type={"nda_transaction"}
              fileName={t(
                "platform/common:content.investor_matching.documents.nda_transaction_download"
              )}
              handleDownloadDocument={handleDownloadDocument}
            />
            <UploadFileCard
              onFileUpload={async (fileId) => {
                const transactionUpdateData = {
                  metadata: [
                    {
                      scope: "state",
                      data: {
                        custom_company_contract: fileId,
                      },
                    },
                  ],
                };
                await updateTransactionMutation({
                  transactionId: transactionId,
                  body: transactionUpdateData,
                });
              }}
              fileName={"Mandatsvertrag"}
            />
            <SignDocumentCard
              fileName="Mandatsvetrag"
              onSignDocument={() => {
                handleSignDocument("contract_company");
              }}
            />
          </Stack>
        </Card>
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "70%",
                maxWidth: "400px",
                height: "300px",
              },
            },
          }}
          open={fileToDownload !== null}
          onClose={() => {
            setFileToDownload(null);
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            {selectedType && (
              <>
                <Typography variant="inherit" style={{ fontSize: 24, fontWeight: "bold" }}>
                  {companyName}
                </Typography>
                <Typography variant="inherit" style={{ fontSize: 16 }}>
                  {typeName[selectedType]}
                </Typography>
              </>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "50px",
                fontSize: 20,
              }}
              id="alert-dialog-slide-description"
            >
              {t("platform/common:content.secondary_download_dialog.dialog_content_text")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size={isMobile ? "small" : "medium"}
              sx={{
                px: { sx: 2, md: 4 },
                fontWeight: "bold",
                color: theme.palette.secondary.main,
              }}
              onClick={() => {
                window.open(fileToDownload, "_blank");
                setFileToDownload(null);
              }}
            >
              Hereunterladen
            </Button>
          </DialogActions>
        </Dialog>
      </Section>
      <Section
        title={t("details_a3.documents_management_card.headline")}
        ref={DocumentsManagementRef}
      >
        <Card>
          {loadingDetails && <LoadingIndicator type={"COMPONENT"} />}
          {!loadingDetails && (
            <DocumentsTable
              originalFiles={detailsTransaction?.files}
              product={"platform"}
              page={"details"}
            />
          )}
          {loadingDetails && <LoadingIndicator type={"COMPONENT"} />}
          {/* Add conditional check to ensure transactionId is present and a string */}
          {emails && emails.length > 0 && typeof detailsTransaction?.transaction_id === "string" && (
            <DocumentSignDialog
              isOpen={documentToSign !== null}
              fileUrl={documentToSign}
              envelopeId={docuSignEnvelopeId}
              header={companyName}
              isFinalized={isFinalized}
              onClose={() => {
                setDocumentToSign(null);
              }}
              onSign={() => {
                setDocumentToSign(null);
              }}
              emails={emails}
              onSendEnvelope={async (envelopeId) => {
                const transactionId = detailsTransaction.transaction_id; // Here is MUST be a string !!!

                // Perform checks for both transactionId and envelopeId
                if (transactionId && typeof transactionId === "string" && envelopeId) {
                  const transactionUpdateData = {
                    metadata: [
                      {
                        scope: "state",
                        data: {
                          contract_company_envelope_id: envelopeId,
                          is_finalized: false,
                        },
                      },
                    ],
                  };

                  // Update the transaction when both transactionId and envelopeId are valid
                  await updateTransaction(transactionId, transactionUpdateData);
                } else {
                  // console.error("Transaction ID or Envelope ID is missing or invalid");
                }
              }}
              onAllSigned={async (doc) => {
                const file = new File([doc.data], "Mandatsvetragunterschrieben.pdf", {
                  type: "application/pdf",
                });

                const transactionId = detailsTransaction.transaction_id;
                const transactionUpdateData = {
                  metadata: [
                    {
                      scope: "state",
                      data: {
                        is_finalized: true,
                      },
                    },
                  ],
                };

                await updateTransaction(transactionId, transactionUpdateData);

                await s3Upload(TConfig.file.URN.CONTRACT_COMPANY, file, [
                  {
                    urn: TConfig.record.URN.TRANSACTION,
                    value: transaction?.transaction_id,
                  },
                ]);
              }}
            />
          )}
          onAllSigned=
          {async (doc) => {
            const file = new File([doc.data], "Mandatsvetragunterschrieben.pdf", {
              type: "application/pdf",
            });

            const transactionId = detailsTransaction.transaction_id;
            const transactionUpdateData = {
              metadata: [
                {
                  scope: "state",
                  data: {
                    // @ts-ignore
                    is_finalized: true,
                  },
                },
              ],
            };

            await updateTransaction({
              transactionId: transactionId,
              body: transactionUpdateData,
            });

            await s3Upload(TConfig.file.URN.CONTRACT_COMPANY, file, [
              {
                urn: TConfig.record.URN.TRANSACTION,
                value: transaction?.transaction_id,
              },
            ]);
            // await handleClientAgreementUploaded(doc);
          }}
        </Card>
      </Section>
      <Stack direction="row" justifyContent="flex-end">
        <PrimaryButton
          disabled={disableSaveButton}
          onClick={() => {
            handleUpdateClientTransactionData();
            setInitial(false);
          }}
          title={t("misc.save")}
        />
      </Stack>
    </Box>
  );
};

export default Details;

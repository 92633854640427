import { styled } from "@mui/material/styles";
import { lighten } from "@mui/system";

export const Wrapper = styled("div")(({ className }) => {
  return {
    width: "100%",
    height: className === "open" ? "100%" : "0px",
    justifyContent: "center",
    overflowY: className === "open" ? "" : "hidden",
  };
});

export const CatHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.7),
}));

export const IndItems = styled("ul")({
  padding: 0,
});

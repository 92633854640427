import { Button, TextField, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useTransactions } from "../../../hooks";
import { useEmailTemplate } from "../../../hooks/useEmailTemplate";
import { useSelector, useDispatch } from "react-redux";
import { loadLanguage } from "../../../Store/languageChange/languageSlice"; // Import the loadLanguage action

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const EmailTemplate = ({ transactionId }) => {
  const theme = useTheme();
  const { updateTransaction } = useTransactions();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const language = useSelector(state => state.language[transactionId]); // Get language from Redux store

  // Load language from localStorage or default when the component mounts
  useEffect(() => {
    dispatch(loadLanguage({ transactionId }));
  }, [dispatch, transactionId]);

  const {
    subject: deSubject,
    setSubject: setDeSubject,
    bodyMessage: deBodyMessage,
    setBodyMessage: setDeBodyMessage,
  } = useEmailTemplate(transactionId, "de");

  const {
    subject: enSubject,
    setSubject: setEnSubject,
    bodyMessage: enBodyMessage,
    setBodyMessage: setEnBodyMessage,
  } = useEmailTemplate(transactionId, "en");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updateEmailTemplate = () => {
    setLoading(true);
    const body = {
      metadata: [
        {
          scope: "email_template",
          data: {
            en: {
              bodyMessage: enBodyMessage,
              subject: enSubject,
            },
            de: {
              bodyMessage: deBodyMessage,
              subject: deSubject,
            },
          },
        },
      ],
    };

    updateTransaction(transactionId, body)
      .then(() => {
        setLoading(false);
        setOpen(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Button
        onClick={handleOpen}
        variant="contained"
        component="span"
        sx={{
          px: 4,
          fontWeight: "bold",
          marginTop: "30px !important",
          color: theme.palette.secondary.main,
          margin: "20px 20px",
        }}
      >
        Email Template
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <TextField
              label="Selected Language"
              value={language === "de" ? "German" : "English"}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            {language === "de" ? (
              <div>
                <TextField
                  value={deSubject}
                  label="Subject (DE)"
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  onChange={(e) => setDeSubject(e.target.value)}
                />
                <TextField
                  value={deBodyMessage}
                  multiline
                  rows={10}
                  label="Body (DE)"
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  onChange={(e) => setDeBodyMessage(e.target.value)}
                />
              </div>
            ) : (
              <div>
                <TextField
                  value={enSubject}
                  label="Subject (EN)"
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  onChange={(e) => setEnSubject(e.target.value)}
                />
                <TextField
                  value={enBodyMessage}
                  multiline
                  rows={10}
                  label="Body (EN)"
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  onChange={(e) => setEnBodyMessage(e.target.value)}
                />
              </div>
            )}
            <Button
              disabled={loading}
              variant="contained"
              component="span"
              onClick={updateEmailTemplate}
              sx={{
                px: 4,
                fontWeight: "bold",
                color: theme.palette.secondary.main,
              }}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

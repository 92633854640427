import { BrowserRouter as Router } from "react-router-dom";

import Private from "./views/Private";
import Public from "./views/Public";

import { ThemeProviderApp } from "./elements/frontend/src/Theme";
import { ErrorBoundary, LoadingIndicator } from "./elements/frontend/src/components";
import { useAuthState } from "./elements/frontend/src/hooks";

const App = () => {
  const { authenticating, authenticated, readyState } = useAuthState();
  const loading = authenticating || (authenticated && readyState !== "READY");

  return (
    <ErrorBoundary>
      <Router>
        <ThemeProviderApp>
          <ErrorBoundary>
            {loading ? (
              <LoadingIndicator type={"PAGE"} />
            ) : authenticated ? (
              <Private />
            ) : (
              <Public />
            )}
          </ErrorBoundary>
        </ThemeProviderApp>
      </Router>
    </ErrorBoundary>
  );
};

export default App;

import { API } from "aws-amplify";
import { getCovid } from "../common";
import TConfig from "../../../../config";
import { useState, useEffect } from "react";

export const getLawyers = () => {
  return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.LAWYERS_API_ENDPOINT}`, {
    queryStringParameters: {
      cov_id: getCovid(),
    },
  });
};

export const useLawyers = () => {
  const [lawyers, setLawyers] = useState([]);

  useEffect(() => {
    const fetchLawyer = () => {
      try {
        getLawyers().then((response) => {
          setLawyers(response.lawyers);
        });
      } catch (e) {
        // Handle the error if needed
      }
    };

    fetchLawyer();
  }, []);

  return lawyers;
};

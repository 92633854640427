import { isValidLengthString } from "../../../elements/frontend/src/common/isValidLengthString";

const isValid = (name, customer) => {
  const advisor = customer.metadata.find((item) => item.scope === "advisor")?.data || false;
  const profile = customer.metadata.find((item) => item.scope === "profile")?.data || false;
  const investment = customer.metadata.find((item) => item.scope === "investment")?.data || false;
  const susa = customer.metadata.find((item) => item.scope === "susa")?.data || false;
  const state = customer.metadata.find((item) => item.scope === "state")?.data || false;
  const isAdvisor = advisor && customer.type !== "Transaction";

  if (customer) {
    switch (name) {
      // ALL
      case "name":
        return isValidLengthString(customer[name], 1);
      case "line_1":
        return isValidLengthString(customer.address[name], 1);
      case "postal":
        return isValidLengthString(customer.address[name], 1);
      case "city":
        return isValidLengthString(customer.address[name], 1);
      case "country":
        return isValidLengthString(customer.address[name], 1);
      case "first_name":
        return isValidLengthString(customer.contact[name], 1);
      case "last_name":
        return isValidLengthString(customer.contact[name], 1);
      case "telephone":
        return isValidLengthString(customer.contact[name], 1);
      case "categories":
        return customer?.type === "Transaction"
          ? profile?.categories?.length > 0
          : investment
          ? investment?.categories?.length > 0
          : advisor?.categories?.length > 0;
      // ADVISOR and INVESTOR
      case "phases":
        return isAdvisor ? advisor?.phases?.length > 0 : investment?.phases?.length > 0;
      case "region":
        return isAdvisor ? advisor?.region?.length > 0 : investment?.region?.length > 0;
      case "foundation":
        return isAdvisor ? isValidLengthString(String(profile[name]), 1) : true;
      case "locations":
        return isAdvisor ? isValidLengthString(String(profile[name]), 1) : true;
      case "consultant":
        return isAdvisor ? isValidLengthString(String(profile[name]), 1) : true;
      case "trans_number":
        return isAdvisor ? isValidLengthString(String(profile[name]), 1) : true;
      case "stake":
        return investment
          ? investment[name].length > 0
          : customer.invest
          ? customer.invest[name].length > 0
          : true;
      case "types":
        return investment ? investment[name].length > 0 : true;
      case "period":
        return investment && "period" in investment
          ? investment.period.length > 0
          : "invest" in customer
          ? isValidLengthString(customer.invest[name], 1)
          : true;
      case "invest":
        return investment ? isValidLengthString(investment[name], 1) : true;
      case "revenue":
        return investment ? isValidLengthString(investment[name], 1) : true;
      case "ebitda":
        return investment ? isValidLengthString(investment[name], 1) : true;

      // COMPANY
      case "legal_form":
        return isValidLengthString(profile[name], 1);
      case "fiscal_year":
        return isValidLengthString(profile[name], 1);
      case "trade_registration":
        return isValidLengthString(profile[name], 1);
      case "no_bankruptcy":
        return profile[name];
      case "no_project_business":
        return profile[name];
      case "website":
        return isValidLengthString(profile[name], 1);
      case "sub_categories":
        //return profile[name].length > 0
        return true;
      case "min_cap":
        return isValidLengthString(customer.invest[name], 1);
      case "max_cap":
        return isValidLengthString(customer.invest[name], 1);
      case "phase":
        return isValidLengthString(customer.invest[name], 1);
      case "self_ev":
        return isValidLengthString(customer.invest[name], 1);
      case "type":
        return customer.invest[name].length > 0;
      case "structure":
        return isValidLengthString(customer.invest[name], 1);
      case "sales_prev_year":
        return isValidLengthString(String(susa[name]), 1);
      case "sales_last_year":
        return isValidLengthString(String(susa[name]), 1);
      case "ebitda_prev_year":
        return isValidLengthString(String(susa[name]), 1);
      case "ebitda_last_year":
        return isValidLengthString(String(susa[name]), 1);
      case "ebit_prev_year":
        return isValidLengthString(String(susa[name]), 1);
      case "ebit_last_year":
        return isValidLengthString(String(susa[name]), 1);
      case "number_employees_prev_year":
        return isValidLengthString(String(susa[name]), 1);
      case "number_employees_last_year":
        return isValidLengthString(String(susa[name]), 1);
      case "ownerships":
        return susa[name].length > 0;
      case "avv_received":
        return state[name];
      case "nda_received":
        return state[name];
      default:
        return false;
    }
  }
  return true;
};

export const allFieldsAreValid = (customer) => {
  const isValidBase =
    isValid("name", customer) &&
    isValid("line_1", customer) &&
    isValid("postal", customer) &&
    isValid("city", customer) &&
    isValid("country", customer) &&
    isValid("first_name", customer) &&
    isValid("last_name", customer) &&
    isValid("telephone", customer) &&
    isValid("categories", customer);

  const isValidAdvInv =
    isValid("phases", customer) &&
    isValid("region", customer) &&
    isValid("foundation", customer) &&
    isValid("locations", customer) &&
    isValid("consultant", customer) &&
    isValid("trans_number", customer) &&
    isValid("stake", customer) &&
    isValid("types", customer) &&
    isValid("period", customer) &&
    isValid("invest", customer) &&
    isValid("revenue", customer) &&
    isValid("ebitda", customer) &&
    isValid("invest", customer);

  const isValidClient =
    isValid("legal_form", customer) &&
    isValid("fiscal_year", customer) &&
    isValid("trade_registration", customer) &&
    isValid("no_bankruptcy", customer) &&
    isValid("no_project_business", customer) &&
    isValid("website", customer) &&
    isValid("sub_categories", customer) &&
    isValid("min_cap", customer) &&
    isValid("max_cap", customer) &&
    isValid("phase", customer) &&
    isValid("self_ev", customer) &&
    isValid("stake", customer) &&
    isValid("type", customer) &&
    isValid("period", customer) &&
    isValid("structure", customer) &&
    isValid("sales_prev_year", customer) &&
    isValid("sales_last_year", customer) &&
    isValid("ebitda_prev_year", customer) &&
    isValid("ebitda_last_year", customer) &&
    isValid("ebit_prev_year", customer) &&
    isValid("ebit_last_year", customer) &&
    isValid("number_employees_prev_year", customer) &&
    isValid("number_employees_last_year", customer) &&
    isValid("ownerships", customer) &&
    isValid("avv_received", customer) &&
    isValid("nda_received", customer);

  return customer.type === "Transaction"
    ? isValidBase && isValidClient
    : isValidBase && isValidAdvInv;
};

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useTheme } from "@mui/material/styles";
import { /* FormControlLabel, Switch, */ useMediaQuery } from "@mui/material";
import {
  LoadingIndicator,
  FadeIn,
  UserManagementDialog,
} from "../../../elements/frontend/src/components";
import { CustomerProfileDialog } from "../../../elements/frontend/src/components/ContactTable/CustomerProfileDialog/CustomerProfileDialog";
import { UserProfileDialog } from "../../../elements/frontend/src/components/UserProfileDialog/UserProfileDialog";
import {
  Wrapper,
  User,
  CompanyName,
  UserName,
  ListWrapper,
  List,
  ListItem,
} from "./styled.usermenu";
import TConfig from "../../../config";

const UserMenu = () => {
  const { t } = useTranslation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const currentUser = useSelector(({ currentUser }) => currentUser);

  const [isCustomerProfileDialogOpen, setIsCustomerProfileDialogOpen] = useState(false);
  const [isUserManagementDialogOpen, setIsUserManagementDialogOpen] = useState(false);
  const [isUserProfileDialogOpen, setIsUserProfileDialogOpen] = useState(false);

  const companyName = currentUser.customer.name;
  const first_name = currentUser.user.first_name ? currentUser.user.first_name : "";
  const last_name = currentUser.user.last_name ? currentUser.user.last_name : "";
  const userName = first_name && last_name ? `${first_name} ${last_name}` : currentUser.user.alias;
  const menuref = useRef();

  useEffect(() => {
    if (isMenuVisible) {
      const handler = (e) => {
        if (!menuref.current.contains(e.target)) {
          setIsMenuVisible(false);
        }
      };
      document.addEventListener("mousedown", handler);

      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }
  });

  const handleToggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleLogOut = () => {
    navigate("/logout");
  };

  // for customerProfile Dialog
  const handleCustomerProfileDialogClose = () => {
    setIsCustomerProfileDialogOpen(false);
  };
  const handleCustomerProfileDialog = (e) => {
    setIsMenuVisible(false);
    setIsCustomerProfileDialogOpen(true);
    setIsUserManagementDialogOpen(false);
  };

  // for userManagement Dialog
  const handleUserManagementDialogClose = () => {
    setIsUserManagementDialogOpen(false);
  };
  const handleUserManagementDialog = (e) => {
    setIsMenuVisible(false);
    setIsUserManagementDialogOpen(true);
    setIsCustomerProfileDialogOpen(false);
  };

  // for userProfile Dialog
  const handleUserProfileDialogClose = () => {
    setIsUserProfileDialogOpen(false);
  };
  const handleUserProfileDialog = (e) => {
    setIsMenuVisible(false);
    setIsUserProfileDialogOpen(true);
  };

  return (
    <>
      <Wrapper>
        <User>
          <CompanyName onClick={handleToggleMenu}>
            <span className="company">
              {companyName ? companyName : <LoadingIndicator type={"TEXT"} length={10} />}
            </span>

            <ExpandMoreRoundedIcon fontSize={isMobile ? "small" : "medium"} />
          </CompanyName>
          <UserName>
            {userName ? userName : <LoadingIndicator type={"TEXT"} length={10} />}
          </UserName>

          {isMenuVisible && (
            <FadeIn>
              <ListWrapper ref={menuref}>
                <List>
                  {currentUser.user.root && (
                    <ListItem onClick={handleCustomerProfileDialog}>
                      {t("top_bar.customer_profile_dialog.headline")}
                    </ListItem>
                  )}
                  {currentUser.user.root && (
                    <ListItem onClick={handleUserManagementDialog}>
                      {t("top_bar.user_management_dialog.headline")}
                    </ListItem>
                  )}
                  {!currentUser.user.root && (
                    <ListItem onClick={handleUserProfileDialog}>
                      {t("top_bar.user_profile_dialog.headline")}
                    </ListItem>
                  )}
                  <ListItem onClick={handleLogOut}>{t("top_bar.logout")}</ListItem>
                </List>
              </ListWrapper>
            </FadeIn>
          )}
        </User>
      </Wrapper>

      {/* Dialog for Customer Profile */}
      <CustomerProfileDialog
        dialogOpen={isCustomerProfileDialogOpen}
        handleDialogClose={handleCustomerProfileDialogClose}
        product={TConfig.defaults.PRODUCT_ID}
      />
      {/* Dialog for User Management */}
      <UserManagementDialog
        dialogOpen={isUserManagementDialogOpen}
        handleDialogClose={handleUserManagementDialogClose}
      />
      {/* Dialog for User Profile */}
      <UserProfileDialog
        dialogOpen={isUserProfileDialogOpen}
        handleDialogClose={handleUserProfileDialogClose}
      />
    </>
  );
};

export default UserMenu;

import React, { createContext, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Content from "../../../containers/Content";
import SideBar from "../../../containers/SideBar";
import TopBar from "../../../containers/TopBar";
import { DashboardLayout } from "../../../libs/Layout";

import { LoadingIndicator } from "../../../elements/frontend/src/components";
import { ContentWrapper } from "./styled.login";

import { useUsers, useAdvisors } from "../../../elements/frontend/src/hooks";

// Dashboard Context
const initialDashboardData = {
  allAdvisors: [],
  supervisorUsers: [],
  supervisorOptions: [],
  advisorOptions: [],
  document: {
    date: "",
    dateText: "",
    file: "",
  },
  susa: {
    date: "",
    dateText: "",
    file: "",
  },
  company_client: {},
  dashboard_steps: [
    { label: "Überblick", stepId: "overview" },
    { label: "Status", stepId: "status" },
    { label: "Details", stepId: "details" },
  ],
  dashboard_currentStep: "overview",
  dashboard_showStatus: false,
  dashboard_showDetails: false,
  registered: "", // "self", "advisor"
  registeredLoading: true, // for loading effect in StatusProce
  scrollTo: "",
  detailsContentLoading: true,
  testing_mode: false,
};

export const DashbordContext = createContext(initialDashboardData);
export const DashboardDispatch = createContext((state) => state);

const reducerDashboard = (state, action) => {
  return { ...state, ...action.payload };
};

const Home = () => {
  const { t } = useTranslation(["platform/common"]);

  const [dashboardContext, dashboardDispatch] = useReducer(reducerDashboard, initialDashboardData);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const { getUsers } = useUsers();
  const { getAllAdvisors } = useAdvisors();

  useEffect(() => {
    getUsers().then((response) => {
      const supervisorOptions = response.users.map((user) => ({
        name:
          user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.alias,
        user_id: user.user_id,
      }));
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          supervisorUsers: response.users,
          supervisorOptions,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllAdvisors().then((response) => {
      const advisorOptions = response.advisors.map((advisor) => ({
        name: advisor.name,
        customer_id: advisor.customer_id,
      }));

      // as an advisor option we have "None"
      advisorOptions.push({
        name: t("transaction_tables.none"),
        customer_id: "undefined",
      });
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          allAdvisors: response.advisors,
          advisorOptions,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardDispatch.Provider value={dashboardDispatch}>
          <DashbordContext.Provider value={dashboardContext}>
            <TopBar />
            <ContentWrapper>
              <SideBar />
              <Content />
            </ContentWrapper>
          </DashbordContext.Provider>
        </DashboardDispatch.Provider>
      </DashboardLayout>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
    </>
  );
};

export default Home;

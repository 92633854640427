import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './languageSlice';

const localizedStore = configureStore({
  reducer: {
    language: languageReducer,
  },
});

export type LocalizedStoreState = ReturnType<typeof localizedStore.getState>;
export type LocalizedStoreDispatch = typeof localizedStore.dispatch;

export default localizedStore;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js";

import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import { Section, Phone, CategoriesDropdown } from "../../../elements/frontend/src/components";
import { useCustomers } from "../../../elements/frontend/src/hooks";
import { useGetCategoryTreesQuery } from "../../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { isValidLengthString } from "../../../elements/frontend/src/common";

import { Wrapper } from "./styled.preview";
import { setCurrentUser } from "../../../elements/frontend/src/Store/currentUser/currentUserSlice";
import PreviewInvestor from "./PreviewInvestor/PreviewInvestor";
import PreviewAdvisor from "./PreviewAdvisor/PreviewAdvisor";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";

const Preview = ({
  customerOriginal,
  openPreview,
  setOpenPreview,
  selectedCustomer,
  setSelectedCustomer,
  setFinalRegisters,
  handleNewRegisteredCheckboxChange = () => {},
}) => {
  const { t } = useTranslation(["advisor/common"]);
  // For the moment account data will be updated, later it will be converted with client functions
  const { updateCustomer } = useCustomers();

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;

  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState(false);
  const [profile, setProfile] = useState(false);
  const [advisor, setAdvisor] = useState(false);
  const [investment, setInvestment] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const customerId = customerOriginal?.id;
  const customerType = customerOriginal?.type;

  const stepsObject = t("platform/common:transaction_tables.process_flow.steps", {
    returnObjects: true,
  });
  // to assign text from locales for advisor in new registered customers
  const advisorObject = t("platform/common:content.overview.new_registered_customers.advisor", {
    returnObjects: true,
  });
  // to assign text from locales for investor in new registered customers
  const investorObject = t("platform/common:content.overview.new_registered_customers.investor", {
    returnObjects: true,
  });
  // Country options for select "Land"
  const countries = t("advisor/common:content.countries", {
    returnObjects: true,
  });
  const countriesArr = Object.keys(countries).map((code) => ({
    code,
    name: countries[code],
  }));

  const handleClosePreview = () => {
    setOpenPreview(false);
    setSelectedCustomer(false);
    setCustomer(false);
    setProfile(false);
    setAdvisor(false);
    setInvestment(false);
  };

  const fetchCustomer = useCallback(() => {
    setCustomer(customerOriginal);
    if (customerType === "Advisor") {
      const profileData = customerOriginal.metadata.find((item) => item.scope === "profile")
        ?.data || {
        completedSteps: [],
        consultant: "",
        currentStep: "",
        foundation: "",
        lastStep: "",
        locations: "",
        trans_number: "",
      };
      const advisorData = customerOriginal.metadata.find((item) => item.scope === "advisor")
        ?.data || {
        categories: [],
        sub_categories: [],
        phases: [],
        region: [],
      };
      setProfile(profileData);
      setAdvisor(advisorData);
    } else {
      const investmentData = customerOriginal.metadata.find((item) => item.scope === "investment")
        ?.data || {
        categories: [],
        phases: [],
        contribution: {
          financial_investor: true,
          strategic_investor: true,
          wachstums_investor: true,
        },
        ebitda: "",
        invest: "",
        period: [],
        stake: [],
        types: [],
        region: [],
        revenue: "",
      };
      setInvestment(investmentData);
    }
  }, [customerOriginal, customerType]);

  useEffect(() => {
    if (
      (!customer && customerId) ||
      (customer && customerId && customer.id && customer.id !== customerId)
    ) {
      fetchCustomer();
    }
  }, [fetchCustomer, customer, customerId]);
  const categoryIds = advisor
    ? advisor?.categories
    : investment
    ? investment?.categories?.map((item) => (typeof item === "string" ? item : item?.cat))
    : [];
  //const subCategoryIds = dashboardContext.advisor.sub_categories
  const subCategoryIds = advisor?.sub_categories
    ? advisor?.sub_categories
    : investment.sub_categories
    ? investment?.sub_categories?.map((item) => (typeof item === "string" ? item : item?.cat))
    : [];

  // to convert Autocomplete array values to objects with value, summary fields
  const convertToObjValue = useCallback(
    (value, field) => {
      if (value) {
        if (value.length === 0) return [];
        return value.map((item) => {
          if (field === "region") {
            return countriesArr.find((obj) => obj.code === item);
          } else {
            return currentUser.datastore[field].find((obj) => obj.value === item);
          }
        });
      }
      return [];
    },
    [countriesArr, currentUser.datastore]
  );

  const allFieldsAreValid = () => {
    const allFieldsValid =
      isValid("name", false) &&
      isValid("line_1", false) &&
      isValid("postal", false) &&
      isValid("city", false) &&
      isValid("country", false) &&
      isValid("first_name", false) &&
      isValid("last_name", false) &&
      isValid("telephone", false) &&
      isValid("categories", false) &&
      isValid("phases", false) &&
      isValid("region", false) &&
      isValid("foundation", false) &&
      isValid("locations", false) &&
      isValid("consultant", false) &&
      isValid("trans_number", false) &&
      isValid("stake", false) &&
      isValid("types", false) &&
      isValid("period", false) &&
      isValid("invest", false) &&
      isValid("revenue", false) &&
      isValid("ebitda", false) &&
      isValid("invest", false);
    return allFieldsValid;
  };

  // to check whether input fields are valid or not
  const isValid = (name) => {
    if (customer && ((profile && advisor) || investment)) {
      switch (name) {
        case "name":
          return isValidLengthString(customer[name], 1);
        case "line_1":
          return isValidLengthString(customer.address[name], 1);
        case "postal":
          return isValidLengthString(customer.address[name], 1);
        case "city":
          return isValidLengthString(customer.address[name], 1);
        case "country":
          return isValidLengthString(customer.address[name], 1);
        case "first_name":
          return isValidLengthString(customer.contact[name], 1);
        case "last_name":
          return isValidLengthString(customer.contact[name], 1);
        case "telephone":
          return isValidLengthString(customer.contact[name], 1);
        case "categories":
          return advisor ? advisor?.categories?.length > 0 : investment.categories.length > 0;
        case "phases":
          return advisor ? advisor?.phases?.length > 0 : investment.phases.length > 0;
        case "region":
          return advisor ? advisor?.region?.length > 0 : investment.region.length > 0;
        case "foundation":
          return profile ? isValidLengthString(String(profile[name]), 1) : true;
        case "locations":
          return profile ? isValidLengthString(String(profile[name]), 1) : true;
        case "consultant":
          return profile ? isValidLengthString(String(profile[name]), 1) : true;
        case "trans_number":
          return profile ? isValidLengthString(String(profile[name]), 1) : true;
        case "stake":
          return investment ? investment[name].length > 0 : true;
        case "types":
          return investment ? investment[name].length > 0 : true;
        case "period":
          return investment && "period" in investment ? investment[name].length > 0 : true;
        case "invest":
          return investment ? isValidLengthString(investment[name], 1) : true;
        case "revenue":
          return investment ? isValidLengthString(investment[name], 1) : true;
        case "ebitda":
          return investment ? isValidLengthString(investment[name], 1) : true;
        default:
          return false;
      }
    }

    return true;
  };

  // to validate phone number with libphonenumber-js
  const checkPhoneNumber = (value = true) => {
    if (customer) {
      const phone = parsePhoneNumber(value);
      return phone.isValid();
    }
    return true;
  };

  const checkNumber = (value) => {
    if (value === "") return true;
    if (value === "0") return false;
    const numArr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const filtered = value.split("").map((item) => (numArr.includes(item) ? item : false));
    const res = !filtered.includes(false);
    return res;
  };
  // handle change event
  const handleDataChange = (e, mainField) => {
    const field = e.target.name;
    const value = e.target.value;

    const numberOnly = ["consultant", "trans_number", "postal"];
    const checkNum = numberOnly.includes(field) ? checkNumber(value) : true;
    if (!checkNum) return;

    if (mainField === "name") {
      setCustomer({
        ...customer,
        [field]: value,
      });
    } else if (mainField === "profile") {
      setProfile({
        ...profile,
        [field]: value,
      });
    } else if (mainField === "investment") {
      setInvestment({
        ...investment,
        [field]: value,
      });
    } else {
      setCustomer({
        ...customer,
        [mainField]: {
          ...customer[mainField],
          [field]: value,
        },
      });
    }
  };

  // handle change event in contact telephone
  const handlePhoneChange = (value) => {
    setCustomer({
      ...customer,
      contact: {
        ...customer.contact,
        telephone: value,
      },
    });
  };

  // handle change event in Autocomplete elements
  const handleAutoCompleteSelect = (e, value, field) => {
    if (value) {
      const newValue = value.map((item) => (field === "region" ? item.code : item.value));
      if (advisor) {
        setAdvisor({
          ...advisor,
          [field]: newValue,
        });
      } else {
        setInvestment({
          ...investment,
          [field]: newValue,
        });
      }
    }
  };

  // handle change event in advisor categories
  const handleCategoriesSelect = (categories, type) => {
    // handle change for first Autocomplete , which is "categories"
    const updatedCategoryIds = categories.map((category) => category.value);
    if (type === "category") {
      const updatedSubCategoryIds = removeAllChildSubCategoriesWithoutSelectedRootCategory(
        updatedCategoryIds,
        subCategoryIds,
        categoryTrees
      );
      if (advisor) {
        setAdvisor({
          ...advisor,
          categories: updatedCategoryIds,
          sub_categories: updatedSubCategoryIds,
        });
      } else if (investment) {
        setInvestment({
          ...investment,
          categories: updatedCategoryIds,
          sub_categories: updatedSubCategoryIds,
        });
      }
    } else if (type === "subCategory") {
      if (advisor) {
        setAdvisor({
          ...advisor,
          sub_categories: updatedCategoryIds,
        });
      } else if (investment) {
        setInvestment({
          ...investment,
          sub_categories: updatedCategoryIds,
        });
      }
    }
  };

  const handleUpdateAccountData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (!allFieldsAreValid()) {
        dispatch(setCurrentUser({ loading: false }));
        return;
      }

      if (customer.contact.telephone) {
        const phone = parsePhoneNumber(customer.contact.telephone);
        const isValidPhoneNumber = phone.isValid();

        const formattedNumber = phone.formatInternational();

        if (isValidPhoneNumber) {
          setCustomer({
            ...customer,
            contact: {
              ...customer.contact,
              telephone: formattedNumber,
            },
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      if (allFieldsAreValid()) {
        // to handle dashboard sidebar steps
        const metaData = advisor
          ? [
              {
                scope: "advisor",
                data: {
                  ...advisor,
                },
              },
              {
                scope: "profile",
                data: {
                  ...profile,
                },
              },
            ]
          : [
              {
                scope: "investment",
                data: {
                  ...investment,
                },
              },
            ];

        const objToSave = {
          name: customer.name,
          address: {
            ...customer.address,
          },
          contact: {
            ...customer.contact,
          },
          metadata: [
            ...metaData,
            {
              scope: "state",
              data: {
                data_provided: true,
              },
            },
          ],
        };

        const responseAccount = await updateCustomer(customerId, objToSave);

        if (responseAccount) {
          dispatch(setCurrentUser({ loading: false }));
          setFinalRegisters((prev) => {
            const arr = [...prev];
            return arr.map((item, i) => {
              if (i !== selectedCustomer.index) {
                return item;
              } else {
                return {
                  ...item,
                  metadata: responseAccount.customer.metadata,
                  address: responseAccount.customer.address,
                  contact: responseAccount.customer.contact,
                };
              }
            });
          });
          setCustomer(responseAccount.customer);
        }
      }
    } catch (e) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  const handleConfirmCheck = (field, chkName) => {
    return (e) => {
      const isChecked = e.target.checked;
      setInvestment({
        ...investment,
        [field]: {
          ...investment[field],
          [chkName]: isChecked,
        },
      });
    };
  };

  return (
    <Wrapper className={openPreview === true ? "open" : ""}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          position: "relative",
          top: "10px",
          right: "10px",
        }}
      >
        <IconButton sx={{ padding: "3px" }} onClick={handleClosePreview}>
          <CloseIcon sx={{ fontSize: "35px" }} />
        </IconButton>
      </Box>
      <Box
        id="scrollContainer"
        component="form"
        sx={{
          px: { xs: 2, md: 2 },
          py: { xs: 1, md: 0 },
          width: "100%",
          maxWidth: theme.breakpoints.values.xl,
        }}
      >
        {/****** COMPANY DATA  ************/}
        <Section
          sx={{ paddingTop: "0px" }}
          title={t("advisor/common:content.company.headline")}
          headlineColor="black"
        >
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <TextField
              label={t("advisor/common:content.company.company_name")}
              name="name"
              id="advisor_initial_company_name"
              value={customer?.name || ""}
              size="small"
              fullWidth
              required
              onChange={(e) => handleDataChange(e, "name")}
              error={!isValid("name")}
              helperText={!isValid("name") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-company",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
            <TextField
              label={t("advisor/common:content.company.street")}
              name="line_1"
              id="advisor_initial_street"
              value={customer?.address?.line_1 || ""}
              size="small"
              required
              fullWidth
              onChange={(e) => handleDataChange(e, "address")}
              error={!isValid("line_1")}
              helperText={!isValid("line_1") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-street",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <TextField
              label={t("advisor/common:content.company.postal_code")}
              name="postal"
              id="advisor_initial_postal"
              value={
                customer?.address?.country === "DEU"
                  ? (customer?.address?.postal || "").substring(0, 5)
                  : customer?.address?.postal || ""
              }
              size="small"
              required
              fullWidth
              onChange={(e) => handleDataChange(e, "address")}
              error={!isValid("postal")}
              helperText={!isValid("postal") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-postal",
                form: {
                  autoComplete: "off",
                },
                maxLength: customer?.address?.country === "DEU" ? 5 : undefined,
                onInput: (e) => {
                  e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "");
                },
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <TextField
              label={t("advisor/common:content.company.city")}
              name="city"
              id="advisor_initial_city"
              value={customer?.address?.city || ""}
              size="small"
              required
              fullWidth
              onChange={(e) => handleDataChange(e, "address")}
              error={!isValid("city")}
              helperText={!isValid("city") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-city",
                form: {
                  autoComplete: "off",
                },
                onInput: (e) => {
                  e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z]/g, "");
                },
              }}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="country" required>
                {t("advisor/common:content.company.country")}
              </InputLabel>
              <Select
                labelId="country"
                id="advisor_initial_country"
                name="country"
                value={customer?.address?.country || ""}
                label={t("advisor/common:content.company.country")}
                onChange={(e) => handleDataChange(e, "address")}
              >
                {countriesArr.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {!isValid("country") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
            </FormControl>
          </Stack>
        </Section>

        {/****** CONTACT ************/}
        <Section title={t("advisor/common:content.contact.headline")} headlineColor="black">
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <TextField
              label={t("advisor/common:content.contact.first_name")}
              name="first_name"
              id="advisor_initial_first_name"
              value={customer?.contact?.first_name || ""}
              size="small"
              required
              fullWidth
              onChange={(e) => handleDataChange(e, "contact")}
              error={!isValid("first_name")}
              helperText={!isValid("first_name") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-firstname",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <TextField
              label={t("advisor/common:content.contact.last_name")}
              name="last_name"
              id="advisor_initial_last_name"
              value={customer?.contact?.last_name || ""}
              size="small"
              required
              fullWidth
              onChange={(e) => handleDataChange(e, "contact")}
              error={!isValid("last_name")}
              helperText={!isValid("last_name") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-lastname",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 2 }}>
            <Phone
              telephone={customer?.contact?.telephone || "+49"}
              label={t("advisor/common:content.contact.phone")}
              phoneChange={handlePhoneChange}
              mobile={true}
              error={!isValid("telephone") || !checkPhoneNumber(customer?.contact?.telephone)}
              errorText={
                !isValid("telephone")
                  ? t("misc.required_field")
                  : !checkPhoneNumber(customer?.contact?.telephone)
                  ? t("error.invalid_phoneNumber")
                  : ""
              }
              registering={false}
              idLabel={"advisor_initial"}
            />
          </Stack>
        </Section>

        {/********** ADVISOR PROFILE ************/}
        <Section
          title={
            advisor
              ? t("advisor/common:content.advisor.headline")
              : t("investor/common:content.investment.headline")
          }
          headlineColor="black"
        >
          {/*** CATEGORIES ***/}
          <CategoriesDropdown
            handleCategoriesSelect={handleCategoriesSelect}
            selectedCategoryIds={categoryIds}
            selectedSubCategoryIds={subCategoryIds}
            withSubcategories={false}
            initial={initialLoad}
            setInitial={setInitialLoad}
          />
          {/*** CATEGORIES OPTIONAL ***/}
          {investment && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ my: 2 }}
            >
              <TextField
                label={t("investor/common:content.investment.categories_optional")}
                name="categories_optional"
                id="categories_optional"
                value={investment.categories_optional || ""}
                size="small"
                fullWidth
                onChange={(e) => handleDataChange(e, "investment")}
                inputProps={{
                  autoComplete: "new-categories",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Stack>
          )}
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
            {/*** PHASES ***/}
            <FormControl fullWidth size="small">
              <Autocomplete
                multiple
                id="advisor_initial_phases"
                freeSolo
                options={currentUser?.datastore?.phases}
                getOptionLabel={(option) => option.summary}
                value={
                  convertToObjValue(advisor ? advisor?.phases : investment?.phases, "phases") || []
                }
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t("advisor/common:content.advisor.phases")}
                    error={!isValid("phases")}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                onChange={(e, value) => handleAutoCompleteSelect(e, value, "phases")}
                isOptionEqualToValue={(option, value) => option.summary === value.summary}
              />
              {!isValid("phases") && (
                <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
              )}
              <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                {t("misc.multi_select")}
              </Typography>
            </FormControl>

            {/*** REGION ***/}
            <FormControl fullWidth size="small">
              <Autocomplete
                multiple
                id="advisor_initial_region"
                freeSolo
                options={countriesArr} // for the moment we heve only DEU option, later the number will increase
                getOptionLabel={(option) => countries[option.code]}
                value={
                  convertToObjValue(advisor ? advisor?.region : investment.region, "region") || []
                }
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t("advisor/common:content.advisor.region")}
                    error={!isValid("region")}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                onChange={(e, value) => handleAutoCompleteSelect(e, value, "region")}
                isOptionEqualToValue={(option, value) => option.code === value.code}
              />
              {!isValid("region") && (
                <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
              )}
            </FormControl>
          </Stack>
        </Section>

        {/********** OTHER INFORMATION ************/}

        <PreviewAdvisor profile={profile} handleDataChange={handleDataChange} isValid={isValid} />

        <PreviewInvestor
          investment={investment}
          handleDataChange={handleDataChange}
          isValid={isValid}
          handleAutoCompleteSelect={handleAutoCompleteSelect}
          handleConfirmCheck={handleConfirmCheck}
          convertToObjValue={convertToObjValue}
        />

        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" my={2}>
          <Button
            onClick={(e) => {
              handleUpdateAccountData();
              setInitialLoad(false);
              handleNewRegisteredCheckboxChange(
                e,
                customerOriginal.next_step,
                customerOriginal.id,
                customerOriginal.supervisor,
                customerOriginal.newTransaction,
                customerOriginal.transaction_id
              );
            }}
            disabled={customerOriginal?.next_action === "Freigabe" ? !allFieldsAreValid() : false}
            variant="contained"
            color="secondary"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { xs: 3, md: 6 },
              mr: 3,
              fontWeight: "bold",
              borderRadius: "4px",
              color: theme.palette.primary.main,
            }}
          >
            {customerOriginal.type === "Advisor"
              ? advisorObject[customerOriginal.next_step]
              : customerOriginal.type === "Investor"
              ? investorObject[customerOriginal.next_step]
              : stepsObject[customerOriginal.next_step]}
          </Button>
          <Button
            variant="contained"
            color="blue"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { xs: 3, md: 6 },
              mr: 1,
              fontWeight: "bold",
              color: "white",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: theme.palette.blue.active,
              },
            }}
            onClick={() => {
              handleUpdateAccountData();
              setInitialLoad(false);
            }}
          >
            {t("misc.save")}
          </Button>
        </Stack>
      </Box>
    </Wrapper>
  );
};

export default Preview;

import React, { useEffect, useMemo, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TConfig from "../../../../../config";
import { useParams } from "react-router-dom";
import { s3Upload } from "./awsInvestmentTeaser";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { useMetadata } from "../../hooks/useMetadata";
import { useFileUrl } from "./useFileUrl";
import languageStore from "../../Store/languageChange/languageStore";
import { setLanguage, loadLanguage } from "../../Store/languageChange/languageSlice";

const useFileAttr = (transactionId, language) => {
  return useMemo(() => {
    if (!transactionId) return undefined;
    return [
      {
        urn: TConfig.record.URN.TRANSACTION,
        value: transactionId,
      },
      {
        urn: TConfig.record.URN.LANGUAGE,
        value: language,
      },
    ];
  }, [transactionId, language]);
};

const InvestmentTeaserPlatformComponent = ({ onLanguageChange, disabled }) => {
  const { t } = useTranslation(["platform/common"]);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const { transactionId } = useParams();
  const fileUrn = "invest_teaser";
  const dispatch = useDispatch();
  const [disableUpload, setDisableUpload] = useState(false);
  const { patchMetadata } = useMetadata();
  const { fileUrls, isLoading } = useFileUrl(transactionId);
  const language = useSelector((state) => state.language[transactionId]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    dispatch(loadLanguage({ transactionId }));
  }, [dispatch, transactionId]);

  const FILE_ATTR = useFileAttr(transactionId);

  useEffect(() => {
    const productId = currentUser?.customer.subscription.product_id;
    if (productId === "platform") {
      setDisableUpload(false);
    }
  }, [currentUser]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    dispatch(setLanguage({ transactionId, language: selectedLanguage }));
    if (onLanguageChange) {
      onLanguageChange(event);
    }
  };

  const handleUpload = async (file) => {
    try {
      setUploading(true);
      dispatch(setCurrentUser({ loading: true }));

      const { filename } = await s3Upload(fileUrn, file, FILE_ATTR);
      if (filename) {
        const data = {
          scope: "profile",
          data: {
            language: language,
          },
        };

        const patchMetadataCallBack = async (retryInterval = 10000) => {
          try {
            await patchMetadata({ resourceId: filename, resourceType: "file", data });
            dispatch(setCurrentUser({ loading: false }));
            setUploading(false);
            window.location.reload();
          } catch (error) {
            // console.error("Failed to patch metadata. Retrying in 10 seconds...", error);
            setTimeout(() => patchMetadataCallBack(retryInterval), retryInterval);
          }
        };

        await new Promise((resolve) => setTimeout(resolve, 1000));
        patchMetadataCallBack();
      }
    } catch (error) {
      // console.error("Upload failed", error);
      dispatch(setCurrentUser({ loading: false }));
      setUploading(false);
    }
  };

  const handlePreview = () => {
    if (uploading) {
      // If uploading, disable preview
      return;
    }
    const fileUrl = fileUrls[language];
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      // console.error("File URL for the selected language not found");
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {!disableUpload && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="file"
            id="file"
            onChange={(e) => handleUpload(e.target.files[0])}
            style={{ display: "none" }}
          />
          <label htmlFor="file">
            <Button
              color="secondary"
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon sx={{ height: "25px" }} />}
              sx={{
                px: 4,
                fontWeight: "bold",
                marginTop: "30px !important",
                margin: "20px 20px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              disabled={uploading} // Disable the upload button while uploading
            >
              {uploading ? (
                <CircularProgress size={24} sx={{ color: "#ff2c2c" }} />
              ) : (
                <>
                  {t("platform/common:content.investor_matching.action.investment_teaser_upload")}{" "}
                  <select
                    value={language || "de"}
                    onChange={handleLanguageChange}
                    style={{
                      color: "#fff",
                      backgroundColor: "#000000",
                      border: "none",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                      padding: "8px 16px",
                      borderRadius: "4px",
                      marginLeft: "10px",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                      textAlign: "center",
                    }}
                  >
                    <option value="de">DE</option>
                    <option value="en">EN</option>
                  </select>
                </>
              )}
            </Button>
          </label>
        </div>
      )}

      <Button
        color="secondary"
        disabled={disabled || uploading} // Disable the preview button while uploading
        onClick={handlePreview}
        variant="contained"
        component="span"
        startIcon={<VisibilityIcon sx={{ height: "25px" }} />}
        sx={{
          px: 4,
          fontWeight: "bold",
          marginTop: "30px !important",
          margin: "20px 20px",
        }}
      >
        {t("platform/common:content.investor_matching.action.show_investment_teaser")}{" "}
      </Button>
    </div>
  );
};

export const InvestmentTeaserPlatform = (props) => (
  <Provider store={languageStore}>
    <InvestmentTeaserPlatformComponent {...props} />
  </Provider>
);

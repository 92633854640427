import { API } from "aws-amplify";
import { getCovid } from "../common";
import TConfig from "../../../../config";

type TUseMagicLinkProps = {
  transaction_id: string;
  customer_id: string;
  locale: string;
};

type TUseMagicLinkRetVal = {
  getMagicLink: (props: TUseMagicLinkProps) => Promise<any>;
};

export const useMagicLink = (): TUseMagicLinkRetVal => {
  const getMagicLink = async ({ transaction_id, customer_id, locale }: TUseMagicLinkProps) => {
    // Make the API call and return the response
    try {
      const response = await API.get(
        TConfig.webapp.APP_API_ID,
        `${TConfig.webapp.LOGINS_API_ENDPOINT}/${customer_id}`,
        {
          queryStringParameters: {
            cov_id: getCovid(),
            transaction_id,
            locale
          },
        }
      );
      return response;
    } catch (error) {
      // console.error("Error fetching magic link:", error);
      throw error; // Rethrow the error so the caller can handle it
    }
  };

  return {
    getMagicLink,
  };
};
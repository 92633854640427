import React from "react";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import TConfig from "../../../../../config";
import { s3Upload } from "./awsInvestmentTeaser";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { useEffect, useState } from "react";
import { useTransactions } from "../../hooks";

// Custom hook to handle file attributes
const useFileAttr = (transactionId) => {
  return useMemo(() => {
    if (!transactionId) return undefined;
    return [
      {
        urn: TConfig.record.URN.TRANSACTION,
        value: transactionId,
      },
    ];
  }, [transactionId]);
};

const useDisablePreview = (transactionId) => {
  const { getTransactionFiles } = useTransactions();
  const [disablePreview, setDisablePreview] = useState(false);
  useEffect(() => {
    const fetchFiles = async () => {
      const files = await getTransactionFiles(transactionId);
      const filteredFiles = files.files.filter((file) => file.file_urn === "invest_factbook");
      if (filteredFiles.length === 0) {
        setDisablePreview(true);
      }
    };
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return disablePreview;
};

const useDisableUpload = (transactionId) => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const [disableUpload, setDisableUpload] = useState(false);
  useEffect(() => {
    const productId = currentUser?.customer.subscription.product_id;
    if (productId === "platform") {
      setDisableUpload(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return disableUpload;
};

export default function InvestmentDossierPlatform() {
  const { t } = useTranslation(["platform/common"]);
  //const { getTransactionFiles } = useTransactions();
  const theme = useTheme();
  const { transactionId } = useParams();
  const FILE_ATTR = useFileAttr(transactionId);
  const fileUrn = "invest_factbook";
  const dispatch = useDispatch();
  const disablePreview = useDisablePreview(transactionId);
  const disableUpload = useDisableUpload(transactionId);

  const handleUpload = async (file) => {
    try {
      dispatch(setCurrentUser({ loading: true }));
      await s3Upload(fileUrn, file, FILE_ATTR);
      dispatch(setCurrentUser({ loading: false }));
      window.location.reload();
    } catch (error) {}
  };

  const handlePreview = async () => {
    const fileToOpen = `${window.location.origin}/investment-dossier/${transactionId}`;
    window.open(fileToOpen, "_blank", "");
  };
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {!disableUpload && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="file"
            id="InvestmentDossier"
            onChange={(e) => handleUpload(e.target.files[0])}
            style={{ display: "none" }}
          />
          <label htmlFor="InvestmentDossier">
           { <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon sx={{ height: "25px" }} />}
              sx={{
                px: 4,
                fontWeight: "bold",
                marginTop: "30px !important",
                color: theme.palette.secondary.main,
                margin: "20px 20px",
              }}
            >
              {t("platform/common:content.investor_matching.action.investment_dossier_upload")}{" "}
            </Button>}
          </label>
        </div>
      )}

      <Button
        onClick={handlePreview}
        variant="contained"
        component="span"
        startIcon={<VisibilityIcon sx={{ height: "25px" }} />}
        sx={{
          px: 4,
          fontWeight: "bold",
          marginTop: "30px !important",
          color: theme.palette.secondary.main,
          margin: "20px 20px",
        }}
        disabled={disablePreview}
      >
        {t("platform/common:content.investor_matching.action.show_investment_dossier")}{" "}
      </Button>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useTransactions } from "../../elements/frontend/src/hooks";
import { InvestorProps } from "../../elements/frontend/src/Store/interfaces";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useDispatch } from "react-redux";
export function useInvitedInvestors({ transactionId, investors, transaction }: any) {
  const { updateTransaction } = useTransactions();
  const [invitations, setInvtations] = useState<InvestorProps[]>([]);
  const dispatch = useDispatch();

  const saveInvestors = async (invitations: InvestorProps[]) => {
    const body = {
      investors: invitations.map((inv) => ({
        customer_id: inv.id,
      })),

      metadata: [
        {
          scope: "investment",
          data: {
            investors: invitations,
          },
        },
      ],
    };

    await updateTransaction(transactionId, body);
  };

  useEffect(() => {
    const savedInvitations = transaction?.metadata.find(
      (metadata: any) => metadata.scope === "investment"
    )?.data?.investors;

    if (savedInvitations && investors) {
      const validInvitations = savedInvitations.filter((invitation: InvestorProps) =>
        investors.some((inv: InvestorProps) => inv.id === invitation.id)
      );

      if (validInvitations.length !== savedInvitations.length) {
        console.log("Found deleted investors in invitations, updating...");
        saveInvestors(validInvitations);
      }

      setInvtations(validInvitations);
    }
  }, [transaction, investors]); // eslint-disable-line react-hooks/exhaustive-deps

  const isInvited = (id: string) => {
    if (Array.isArray(invitations)) {
      const exists = invitations.filter((inv: InvestorProps) => inv.id === id);
      return exists.length > 0;
    }
  };

  const inviteInvestor = async (investor: InvestorProps) => {
    if (isInvited(investor.id)) return;
    dispatch(setCurrentUser({ loading: true }));
    const updateInvestor = [...invitations, investor];
    setInvtations(updateInvestor);
    await saveInvestors(updateInvestor);
    const investors = updateInvestor.map((inv) => ({
      customer_id: inv.id,
      metadata: [
        {
          scope: "state",
          data: {
            invited: true,
          },
        },
      ],
    }));

    const body = {
      investors: investors,
    };

    await updateTransaction(transactionId, body);
    dispatch(setCurrentUser({ loading: false }));
  };

  const unInviteInveestor = async (investor: InvestorProps) => {
    const updateInvestor = invitations.filter((inv) => inv.id !== investor.id);
    setInvtations(updateInvestor);
    saveInvestors(updateInvestor);
  };

  return {
    inviteInvestor,
    invitations,
    loadingInvitations: false,
    isInvited,
    unInviteInveestor,
  };
}

import { TableHead, TableRow, TableCell, Box } from "@mui/material";
import { GlobalFilter } from "./helper";
import { StyledTableCell, StyledTableRow } from "./styled.tableComp";

const InvestorTableHead = (props) => {
  const { 
    headerGroups, 
    globalFilter, 
    setGlobalFilter, 
    preGlobalFilteredRows, 
    visibleColumns 
  } = props;

  if (!headerGroups || !visibleColumns) {
    return null;
  }

  return (
    <TableHead sx={{ width: "100%" }}>
      <TableRow>
        <TableCell colSpan={visibleColumns.length + 1}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </TableCell>
      </TableRow>
      {headerGroups.map((headerGroup, i) => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <StyledTableRow key={key || i} {...restHeaderGroupProps}>
            {headerGroup.headers.map((column) => (
              <StyledTableCell 
                key={column.id} 
                sx={{ width: "10%", maxWidth: "100px" }}
              >
                <Box component="div">
                  <Box component="div">{column.render("Header")}</Box>
                  {column.canFilter ? (
                    <Box component="div">
                      {column.render("Filter")}
                    </Box>
                  ) : null}
                </Box>
              </StyledTableCell>
            ))}
            <StyledTableCell
              key={`empty-${i}`}
              sx={{
                width: "10%",
                maxWidth: "100px",
              }}
            />
          </StyledTableRow>
        );
      })}
    </TableHead>
  );
};

export default InvestorTableHead;

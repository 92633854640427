import { useMsal } from "@azure/msal-react";
import { getMails } from "../../../libs/microsoft/mail";
import { ActivityCard } from "./ActivityCard";
import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { LoadingIndicator } from "../../../elements/frontend/src/components/LoadingIndicator";
import { Card, CardContent, Typography } from "@mui/material";
import { useEmailTemplate } from "../../../elements/frontend/src/hooks/useEmailTemplate";
import { getExistingInvite } from "../../../elements/frontend/src/Store/invites/inviteState";

interface Investor {
  email: string;
  id: string;
  // Add other properties as needed
}

interface Props {
  investor: Investor;
  transactionId: string;
}

export const InvestorOverview: React.FC<Props> = ({ investor, transactionId }) => {
  const msal = useMsal();
  const [emails, setEmails] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  // Fetch email templates for both languages
  const {
    subject: subjectDE,
    bodyMessage: bodyMessageDE,
    loading: templateLoadingDE,
  } = useEmailTemplate(transactionId, "de");
  const {
    subject: subjectEN,
    bodyMessage: bodyMessageEN,
    loading: templateLoadingEN,
  } = useEmailTemplate(transactionId, "en");

  useEffect(() => {
    const fetchMails = async () => {
      let searchQuery = investor.email;
      setLoading(true);
      const fetchedMails = await getMails(msal, `${searchQuery}`);
      setEmails(fetchedMails.value);
      setLoading(false);
    };
    fetchMails();
  }, [msal, investor.email]);

  // eslint-disable-next-line no-mixed-operators
  if (loading || (templateLoadingDE && templateLoadingEN)) {
    return (
      <div>
        <LoadingIndicator type={"COMPONENT"} />
      </div>
    );
  }
  if (!emails) {
    return <div></div>;
  }

  // Function to get the invite link based on language
  const getInviteLink = (language: string) => {
    const existingInvite = getExistingInvite();
    // console.log("EXISTING INVITE INDEX:", existingInvite);

    if (existingInvite && existingInvite.locale) {
      const inviteForLocale = existingInvite.locale;
      if (inviteForLocale) {
        return existingInvite.short_url;
      }
    }
    return null;
  };

  const linkDE = getInviteLink("de");
  const linkEN = getInviteLink("en");

  //console.log("Link DE:", linkDE);
  //console.log("Link EN:", linkEN);

  // Encode the body message without URL encoding the link part
  const encodeBody = (body: string, linkText: string | null) => {
    if (!linkText) return body;
    const bodyWithLink = `${body}\n\n${linkText}`;
    return bodyWithLink;
  };

  const bodyMessageWithLinkDE = encodeBody(
    bodyMessageDE,
    linkDE ? `Investment Teaser: ${linkDE}` : null
  );
  const bodyMessageWithLinkEN = encodeBody(
    bodyMessageEN,
    linkEN ? `Investment Teaser: ${linkEN}` : null
  );

  return (
    <div>
      <h1
        style={{
          marginBottom: "8px",
        }}
      >
        Investorenkommunikation
      </h1>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2, // This adds spacing between the buttons
        }}
      >
        <Button
          color="secondary"
          variant="contained"
          size="small"
          endIcon={<SendIcon />}
          sx={{ maxWidth: "20%" }}
          onClick={() => {
            window.open(
              `mailto:${investor.email}?subject=${encodeURI(subjectDE)}&body=${encodeURI(
                bodyMessageWithLinkDE
              )}`
            );
          }}
        >
          E-Mail senden DE
        </Button>

        <Button
          color="secondary"
          variant="contained"
          size="small"
          endIcon={<SendIcon />}
          sx={{ maxWidth: "20%" }}
          onClick={() => {
            window.open(
              `mailto:${investor.email}?subject=${encodeURI(subjectEN)}&body=${encodeURI(
                bodyMessageWithLinkEN
              )}`
            );
          }}
        >
          Email senden EN
        </Button>
      </Box>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "20px",
        }}
      >
        {emails.length === 0 && (
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h6">{"No Emails Found"}</Typography>
            </CardContent>
          </Card>
        )}
        {emails.map((mail) => {
          if (!mail || !mail.sender || !mail.toRecipients) return null;
          return <ActivityCard key={mail.id} type={"email"} email={mail} />;
        })}
      </div>
    </div>
  );
};

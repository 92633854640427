import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Overview from "../../components/Overview";
import Status from "../../components/Status";
import Details from "../../components/Details";
import { Wrapper } from "./styled.content";
import { DueDiligence } from "../../elements/frontend/src/components/DueDiligence/DueDiligence";
import InvestorMatching from "../../components/InvestorMatching/InvestorMatching";
import { Commercial } from "../../elements/frontend/src/components/DueDiligence/components/Commercial/Commercial";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InvestorStatusTable } from "../../elements/frontend/src/components/InvestorStatusTable/InvestorStatusTable";

const Content = () => {
  const params = useParams<{ transactionId: string; step: string }>();
  const step = params.step;
  const hasParams = Object.keys(params).length > 0;
  const msal = useMsal();
  const isAuth = useIsAuthenticated();

  useEffect(() => {
    const auth = async () => {
      if (msal.inProgress !== "none" || isAuth) return;
      await msal.instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    };
    auth();
  }, [msal.instance, msal.inProgress, isAuth]);

  useEffect(() => {
    const fetchMails = async () => {
      if (msal.accounts.length === 0) return;
      const account = msal.accounts[0];
      msal.instance.setActiveAccount(account);
    };
    fetchMails();
  }, [msal, isAuth, msal.instance, msal.accounts.length, msal.inProgress]);

  return (
    <Wrapper>
      {!hasParams && <Overview />}
      {hasParams && step === "status" && <Status />}
      {hasParams && step === "details" && <Details />}
      {hasParams && step === "due-diligence-legal" && <DueDiligence />}
      {hasParams && step === "investor-matching" && <InvestorMatching />}
      {hasParams && step === "due-diligence-commercial" && <Commercial />}
      {hasParams && step === "investor-status" && <InvestorStatusTable />}
    </Wrapper>
  );
};

export default Content;

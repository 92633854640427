import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useFileUrl } from "./useFileUrl";
import { LoadingIndicator } from "../LoadingIndicator";
import { Logo } from "../Logo";
import { Button, Snackbar } from "@mui/material";
import { loadLanguage } from '../../Store/languageChange/languageSlice';
import { useInvestors } from "../../hooks";
import { useMagicLink } from "../../hooks/useMagicLink";

export const InvestmetTeaserView = () => {
  const { transactionId: transaction_id } = useParams();
  const { t } = useTranslation(["platform/common"]);
  const dispatch = useDispatch();
  const { isLoading, fileUrls } = useFileUrl(transaction_id);
  const [height, setHeight] = useState(window.innerHeight);
  const [currentLanguage, setCurrentLanguage] = useState(null); // State to keep track of the current language
  const { getMagicLink } = useMagicLink();
  const { activateInvestor } = useInvestors()

  // here i have to extract query parameters
  // eslint-disable-next-line no-restricted-globals
  const query = new URLSearchParams(location.search);
  const customer_id = query.get('cid');
  const status = query.get('status');
  const covid = query.get('covid');
  const locale = query.get('locale') || 'de';

  useEffect(() => {
    dispatch(loadLanguage({ transactionId: transaction_id })); // Load language from localStorage or default
  }, [dispatch, transaction_id]);

  useEffect(() => {
    if (fileUrls) {
      const languages = Object.keys(fileUrls);
      if (languages.length > 0) {
        setCurrentLanguage(languages[0]); // Set the default language based on fileUrls keys for links in my case
      }
    }
  }, [fileUrls]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handlePreview = async () => {

    if (status === "prospect") {
      await activateInvestor(customer_id)

      await getMagicLink({ customer_id, transaction_id, locale })
     // console.log("customer_id, transaction_id, locale");
      

    } else {
      //other conditions
      await getMagicLink({ customer_id, transaction_id, locale })
    }

  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator type={"PROGRESS"} />}
      {!isLoading && currentLanguage && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Logo />
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: 2, marginBottom: 2, marginRight: 2 }}
              onClick={handlePreview}
            >
              {t("platform/common:content.investor_matching.investor_status.interested")}
            </Button>
          </div>
          <object
            data={fileUrls[currentLanguage]} // Use the correct language-specific file URL
            type="application/pdf"
            title="PDF Viewer"
            width="100%"
            height={height}
          />
        </div>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={t("platform/common:content.email_sent") || "Email has been sent successfully"}
      />
    </>
  );
};

import React, { useState } from "react";

import { Section } from "../../../../elements/frontend/src/components";
import { Stack, TextField } from "@mui/material";
import { Wrapper } from "./styled.PreviewAdvisor";

import { useTranslation } from "react-i18next";
const PreviewAdvisor = ({ profile, handleDataChange, isValid }) => {
  const { t } = useTranslation(["investor/common"]);
  const [invalidFounderYear, setInvalidFounderYear] = useState(false);

  const handleFounderYearInput = (e) => {
    const val = e.target.value;
    const yearVal = val.replace(/[^0-9]/g, "");

    const currentDate = new Date();
    const recentYear = currentDate.getFullYear();
    if (Number(yearVal) > recentYear) {
      setInvalidFounderYear(true);
    } else setInvalidFounderYear(false);

    e.target.value = yearVal;
  };

  const handleOnBlur = (e) => {
    handleFounderYearInput(e);
  };

  return (
    <Wrapper className={profile ? "advisor" : ""}>
      <Section title={t("advisor/common:content.advisor.second_headline")} headlineColor="black">
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
          {/*** FOUNDATION ***/}
          <TextField
            label={t("advisor/common:content.advisor.foundation")}
            name="foundation"
            id="advisor_initial_foundation"
            value={profile?.foundation || ""}
            size="small"
            required
            fullWidth
            onInput={(e) => handleFounderYearInput(e)}
            onChange={(e) => handleDataChange(e, "profile")}
            error={!isValid("foundation")}
            helperText={
              !isValid("foundation")
                ? t("misc.required_field")
                : invalidFounderYear
                ? t("details_a3.company_card.founding_year_invalid")
                : ""
            }
            inputProps={{
              autoComplete: "new-foundation",
              form: {
                autoComplete: "off",
              },
              maxLength: 4,
            }}
            onBlur={() => handleOnBlur}
          />
          {/*** LOCATIONS ***/}
          <TextField
            label={t("advisor/common:content.advisor.locations")}
            name="locations"
            id="advisor_initial_locations"
            value={profile?.locations || ""}
            size="small"
            required
            fullWidth
            onChange={(e) => handleDataChange(e, "profile")}
            error={!isValid("locations")}
            helperText={!isValid("locations") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-locations",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
          {/*** CONSULTANT ***/}
          <TextField
            label={t("advisor/common:content.advisor.consultant")}
            name="consultant"
            id="advisor_initial_consultant"
            value={profile?.consultant || ""}
            size="small"
            required
            fullWidth
            onChange={(e) => handleDataChange(e, "profile")}
            error={!isValid("consultant")}
            helperText={!isValid("consultant") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-locations",
              form: {
                autoComplete: "off",
              },
            }}
          />
          {/*** TRANS_NUMBER ***/}
          <TextField
            label={t("advisor/common:content.advisor.trans_number")}
            name="trans_number"
            id="advisor_initial_trans_number"
            value={profile?.trans_number || ""}
            size="small"
            required
            fullWidth
            onChange={(e) => handleDataChange(e, "profile")}
            error={!isValid("trans_number")}
            helperText={!isValid("trans_number") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-locations",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </Stack>
      </Section>
    </Wrapper>
  );
};

export default PreviewAdvisor;

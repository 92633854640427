import { UploadLink } from "./styled.uploadFileCard";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { FileUploadDialog } from "../../elements/frontend/src/components/FileUploadDialog";

export const UploadFileCard = ({ onFileUpload, fileName }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);

  return (
    <>
      <UploadLink onClick={() => setIsFileUploadDialogOpen(true)} sx={{ pointerEvents: "initial" }}>
        <Paper
          elevation={3}
          sx={{
            width: "140px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,

            transition: "all 0.4s",
            "&:hover": {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            },
            border: "none",
          }}
        >
          <DescriptionOutlinedIcon sx={{ fontSize: "50px" }} />
          <Typography variant="body1" sx={{ my: 1, fontWeight: "bold", fontSize: "13px" }}>
            {fileName}
          </Typography>
          {t("misc.upload")}
        </Paper>
      </UploadLink>
      <FileUploadDialog
        dialogOpen={isFileUploadDialogOpen}
        handleDialogClose={() => setIsFileUploadDialogOpen(false)}
        fileToEdit={null}
        handlePrevNewFileUpdate={([file, fileType, response], event) => {
          onFileUpload(response);
        }}
        product={"platform"}
        page={"details"}
      />
    </>
  );
};

import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ className }) => {
  const height = className === "advisor" ? "auto" : "0px";
  const overflowY = className === "advisor" ? "auto" : "hidden";

  return {
    width: "100%",
    height: height,
    overflowY: overflowY,
    display: "flex",
    justifyContent: "center",
  };
});

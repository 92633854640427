import { styled } from "@mui/material/styles";
// import { lighten } from "@mui/system";

export const Wrapper = styled("div")(({ theme }) => ({
  gridArea: "dashboard",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: "25px 10px 0 10px",
  [theme.breakpoints.up("md")]: {
    padding: "25px 10px 0 15px",
  },
}));

import { styled } from "@mui/material/styles";

export const ContentWrapper = styled("div")(({ theme }) => {
  return {
    gridArea: "content",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateAreas: `
      "sidebarCommon dashboard"
    `,
    gridTemplateColumns: "60px 1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "250px 1fr",
    },
    background: "#000428",
    // eslint-disable-next-line no-dupe-keys
    background: "-webkit-linear-gradient(to top, #004e92, #000428)",
    // eslint-disable-next-line no-dupe-keys
    background: "linear-gradient(to top, #004e92, #000428)",
  };
});
